import React, { useState } from "react";
import BybestCustomerLayout, {
  customerText,
} from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerFooterButtons from "../customer-footer-buttons";
import CustomerHeader from "../customer-header";
import Address from "./address";
import CommunicationPreferences from "./communication-preferences";
import GeneralInfo from "./general-info";
import "./index.css";

const PersonalInformation = () => {
  const [switchStatus, setSwitchStatus] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
  });

  const handleChange = (e) => {
    setData((info) => ({ ...info, [e.target.name]: e.target.value }));
  };

  const onSwitchChange = (checked) => {
    setSwitchStatus(checked);
  };

  return (
    <BybestCustomerLayout
      selected="personal-information"
      key="personal-information"
    >
      <div className="flex flex-1 flex-col gap-5">
        <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg mt-6">
          <div className="p-6 gap-2 flex flex-col">
            <CustomerHeader
              title={customerText("personalInfo")}
              description={customerText("personalInfoDescription")}
              showItems={false}
            />
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
            <GeneralInfo data={data} handleChange={handleChange} />
            <Address data={data} handleChange={handleChange} />
          </div>
          <div className="pr-6">
            <CustomerFooterButtons />
          </div>
        </div>
        <CommunicationPreferences
          switchStatus={switchStatus}
          onSwitchChange={onSwitchChange}
          title={customerText("marketingCommunication")}
          description={customerText("marketingCommunicationDescription")}
        />
        <CommunicationPreferences
          switchStatus={switchStatus}
          onSwitchChange={onSwitchChange}
          title={customerText("promotionCommunication")}
          description={customerText("promotionCommunicationDescription")}
        />
      </div>
    </BybestCustomerLayout>
  );
};

export default PersonalInformation;
