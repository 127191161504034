import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link, useParams } from "react-router-dom";
import BbProduct from "../../../components/BbProduct";
import BybestHomeCarousel from "../../../components/BybestHomeCarousel";
import BybestHomeNotiModal from "../../../components/BybestHomeNotiModal";
import BBLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import BbService from "../../../services/BbService";
import useSeoData from "../../../services/hooks/use-seo";
import "./home.css";

const ByBestHome = () => {
  const { pageSEO } = useSeoData();
  const [showModal, setShowModal] = useState(false);
  const [sliders, setSliders] = useState([]);
  const [homepage_brands, setHomePageBrands] = useState([]);

  const { app_key = "BYB2802APPY" } = useParams();

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, [30 * 1000]);
    loadHomeData();
  }, [app_key]);

  const loadHomeData = () => {
    BbService.getHomePageData(app_key)
      .then((res) => {
        setSliders(res.sliders || []);
        setHomePageBrands(res.homepage_brands || []);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message || "Something went wrong!",
          "Error",
          3000
        );
      });
  };

  const onFavoriteChange = (product) => {};

  return (
    <BBLayout clientKey="bybest" seo={pageSEO}>
      <div className=" w-full bg-white bb-home  ">
        <div className="hidden md:flex w-full bg-[#3B3B3A] py-1 px-4">
          <div className="w-full max-w-screen-main mx-auto">
            <div className="icons">
              {homepage_brands?.map((home_brand, index) => (
                <Link
                  to={`/brand/${home_brand.url}`}
                  key={index}
                  className={"cursor-pointer"}
                >
                  <img
                    src={home_brand.white_logo_path}
                    className="brand-icon"
                    alt={home_brand.title}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className=" w-full flex md:hidden justify-center items-center bg-[#3B3B3A] px-4">
          <div className="w-full flex overflow-x-auto px-4 pb-3 pt-4">
            {homepage_brands?.map((home_brand, index) => (
              <div className="icons" key={index}>
                <Link
                  to={`/brand/${home_brand.url}`}
                  key={index}
                  className={"cursor-pointer"}
                >
                  <img
                    src={home_brand.white_logo_path}
                    className="brand-icon"
                    alt={home_brand.title}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <BybestHomeCarousel sliders={sliders} />
        <div className={`w-full px-4 md:mt-7 mb-5`}>
          <div className="w-full max-w-screen-main mx-auto">
            {homepage_brands?.map((home_brand, index) => (
              <div
                key={index}
                className="grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-3 pt-[20px] md:pt-[50px] pb-[20px] "
              >
                <div className="hidden md:flex h-full">
                  <Link
                    to={`/brand/${home_brand.url}`}
                    className={"w-full h-full block bg-[#f9f9f9]"}
                  >
                    <img
                      src={home_brand.sidebar_logo_path}
                      data-img-url={home_brand.sidebar_logo_path}
                      className="w-full h-full block object-cover rounded-[5px]"
                      alt={home_brand.title}
                    />
                  </Link>
                </div>
                <div class="flex md:hidden w-full justify-between items-center mb-[35px]">
                  <hr className="w-full" />
                  <Link to={`/brand/${home_brand.url}`}>
                    <img
                      src={home_brand.logo_path}
                      data-img-url={home_brand.logo_path}
                      alt={home_brand.title}
                      className="w-[150px] min-w-[150px] max-h-[55px] object-contain px-5"
                    />
                  </Link>
                  <hr className="w-full" />
                </div>
                <div className="col-span-3 flex overflow-x-auto md:overflow-x-hidden overflow-y-hidden md:grid md:grid-cols-3 pb-[10px] md:pb-0 gap-2 md:gap-3">
                  {home_brand.products?.map((product) => (
                    <BbProduct
                      key={product.id}
                      app_key={app_key}
                      product={product}
                      onFavoriteChange={onFavoriteChange}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showModal && (
        <BybestHomeNotiModal
          showModal={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </BBLayout>
  );
};

export default ByBestHome;
