export const xIcon = (
  <svg
    width="52"
    height="51"
    viewBox="0 0 52 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1136_2726)">
      <rect
        x="2.03516"
        y="1.95282"
        width="47.1939"
        height="45.5507"
        rx="7.86565"
        fill="white"
      />
      <rect
        x="2.52676"
        y="2.44442"
        width="46.2107"
        height="44.5675"
        rx="7.37405"
        stroke="#D0D5DD"
        stroke-width="0.983206"
      />
      <path
        d="M20.2901 30.7282L24.1286 25.002L20.4002 19.4017H22.7206L25.3005 23.3896L27.8569 19.4017H30.193L26.4647 25.002L30.2874 30.7282H27.967L25.3005 26.6145L22.6262 30.7282H20.2901Z"
        fill="#344054"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1136_2726"
        x="0.0687438"
        y="0.969614"
        width="51.1281"
        height="49.4835"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.983206" />
        <feGaussianBlur stdDeviation="0.983206" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1136_2726"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1136_2726"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
