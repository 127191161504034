import React from "react";
import Avatar from "../../assets/svgs/alex_r_avatar.svg";
import { useLogout } from "../../services/hooks/use-logout";
import { appKey, links } from "./data";
import { logoutIcon } from "./icons";
import "./index.css";
import { SidebarLink } from "./sidebar-link";
import TierLevel from "./tier-level";
import { customerText } from "../Seo/whitelabel/bybest/bybest-customer-layout";

const Sidebar = ({ selected, open = false, setOpen }) => {
  const handleLogout = useLogout();

  return (
    <div
      className={`sidebar lg:w-[280px] bg-white md:flex flex-col ${
        open ? "fixed top-28 left-2 bg-white h-screen z-50" : "hidden"
      }`}
    >
      <div className="user-profile mt-2">
        <img alt="" src={Avatar} className="w-16 h-16" />
        <div className="user-name">
          <h1>Ronald Richards</h1>
          <p className="text-[#666A73] text-14 ">BB Points: 200</p>
        </div>
      </div>

      <TierLevel className="w-full p-4 mt-4" />

      <div
        className={`flex flex-col gap-5 justify-between ${
          open ? "" : "h-full"
        }  w-full`}
      >
        <div className="sidebar-item-list w-full">
          {links(selected).map((link) => (
            <SidebarLink
              {...link}
              selected={selected === link.key}
              appKey={appKey}
              setOpen={setOpen}
            />
          ))}
        </div>

        <div className="px-4">
          <SidebarLink
            icon={logoutIcon}
            label={customerText("sidebar.signout")}
            onItemClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
