import React from "react";
import LoginSecurity from "../../views/customer/login-security/index.js";
import PersonalInformation from "../../views/customer/personal-information/index.jsx";
import Promotions from "../../views/customer/promotions/index.js";
import Wallet from "../../views/customer/wallet/index.jsx";
import CustomerOrders from "../../views/customer/orders/index.jsx";
import CustomerWishlist from "../../views/customer/wishlist/index.jsx";
import OrderDetails from "../../views/customer/orders/order-details/index.jsx";

const bybestRoutes = [
  {
    path: "customer/personal-information",
    element: <PersonalInformation />,
  },
  {
    path: "customer/login-security",
    element: <LoginSecurity />,
  },
  {
    path: "customer/orders",
    element: <CustomerOrders />,
  },
  {
    path: "customer/orders/:orderId",
    element: <OrderDetails />,
  },
  {
    path: "customer/wishlist",
    element: <CustomerWishlist />,
  },
  {
    path: "customer/promotions",
    element: <Promotions />,
  },
  {
    path: "customer/wallet",
    element: <Wallet />,
  },
];

export default bybestRoutes;
