import React, { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Slider } from 'antd';

const filterItems = [
    {
        label: "MARKAT",
        type: 'checkbox',
        filters: ['BLUKIDS'],
    },
    {
        label: "KOLEKSIONET",
        type: 'checkbox',
        filters: ['VJSHTE-DIMER 2023', 'PRANVERE-VERE 2023', 'PRANVERE-VERE 2024'],
    },
    {
        label: "MASA",
        type: 'checkbox',
        filters: ['40-42', '2/27', '28/30', '28/30', '31/33', '34/36', '37/39'],
    },
    {
        label: "MaTERIALI",
        type: 'checkbox',
        filters: ['PAMBUK'],
    },
    {
        label: "NGJYRA",
        type: 'checkbox',
        filters: ['PAMJE MALORE'],
    },
    {
        label: "GJINIA",
        type: 'checkbox',
        filters: ['M 8-14'],
    },
    {
        label: "FILTRO SIPAS CMIMIT",
        type: 'slider',
        filters: [1258, 6203],
    },
];

const CollapseMenu = ({ filter, isOpened, onSelect,noBorder }) => {
    const [pRange, setPrange] = useState([1258, 6203])
    
    return (
        <div className={`${noBorder ? "" : "border-b border-b-[#D2D2D2]"} w-full`}>
            <button className="w-full flex text-left justify-center items-center py-3 cursor-pointer px-0 h-[43px]" onClick={() => onSelect(filter)}>
                <div className="flex-1"><a className=" no-underline text-[#000] font-extrabold text-[14px] w-full">{filter.label}</a></div>
                <ExpandMoreIcon fontSize={'small'} />
            </button>
                <div className={`w-full overflow-auto transition-all duration-500 ease-in-out transform-gpu origin-top ${isOpened ? "h-max scale-y-100" : "h-0 scale-y-0"}`}>
                    
                    {filter.type == 'checkbox' ?
                        filter.filters.map((item, i) => (
                            <div key={i} className="flex justify-start items-center pb-1">
                                <input type={'checkbox'} className="h-[15px] w-[15px]"/>
                                <p className="text-[16px] ml-1">{item}</p>
                            </div>
                        ))
                        :
                        <div className="w-full px-1">
                            <Slider handleStyle={{ background: '#000' }} trackStyle={{ background: '#000' }} railStyle={{ background: '#999' }} range={{ draggableTrack: true }} min={filter.filters[0]} max={filter.filters[1]} defaultValue={filter.filters} value={pRange} onChange={setPrange} />
                            <div className="mt-2 w-full flex justify-between items-center">
                                <p className="text-[12px] text-[#000]"><b>Min:</b> LEK {pRange[0]}</p>
                                <p className="text-[12px] text-[#000]"><b>Max:</b> LEK {pRange[1]}</p>
                            </div>
                        </div>
                    }
                </div>
            
        </div>
    )
}

const BybestCategoryFilter = () => {
    const [open, setOpen] = useState(true);
    const [activeMenu, setActiveMenu] = useState();

    const handleActiveMenu = (filter)=>{
        if(filter.label === activeMenu?.label){
            setActiveMenu(undefined)
        }else {
            setActiveMenu(filter);
        }
    }

    return (
        <div className="w-full flex flex-col justify-start items-center">
            <div className="w-full flex justify-center items-center py-3 px-4 bg-[#efefef] cursor-pointer" onClick={() => setOpen(pre => !pre)}>
                <p className="flex-1 text-[14px] leading-[21px]">FILTRAT</p>
                <FilterAltIcon style={{ color: '#000', fontSize: 20 }} />
            </div>
            <div className={`w-full pl-[5px] flex flex-col justify-center items-center mt-[10px] mb-10 overflow-hidden transition-all duration-500 ease-in-out transform-gpu origin-top ${
            open ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"
            }`}>
                {filterItems.map((filter,index) => (
                    <CollapseMenu
                    key={filter.label}
                    filter={filter}
                    isOpened={filter.label === activeMenu?.label}
                    onSelect={handleActiveMenu}
                    noBorder={index === (filterItems.length -1)}
                    />
                ))}
            </div>
        </div>
    );
};

export default BybestCategoryFilter;
