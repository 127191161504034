import React from "react";
import BBMainLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import { getBrandTranslation } from "../brands/brands";

const ProductSearch = () => {
  return (
    <BBMainLayout clientKey="bybest">
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase ">
            {getBrandTranslation("breadcrumb")} Category {">"} Products
          </p>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ProductSearch;
