import React from "react";
import "./index.css";
import { arrowDownIcon } from "../../views/bybest/landing/data";

const DropdownFilter = ({
  options = [],
  onChange,
  value = "",
  isSelect = true,
  onClick,
  title = "Filter",
  showIcon = true,
  className = "",
  bb,
}) => {
  return (
    <div className="flex items-center gap-2 cursor-pointer">
      {isSelect ? (
        <select
          onChange={(e) => onChange(e.target.value)}
          className="p-2 border dropdown-layout cursor-pointer"
          value={value}
        >
          {options.map(({ label, value }) => (
            <option className="cursor-pointer" key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      ) : (
        <div
          className={`dropdown-layout items-center gap-2 border p-2 cursor-pointer ${className}`}
          onClick={onClick}
        >
          <p>{title}</p>
          {showIcon && <span>{arrowDownIcon}</span>}
        </div>
      )}
    </div>
  );
};

export default DropdownFilter;
