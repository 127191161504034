import React from "react";
import ByBestSidebar from "./sideBar";
import useSeoData from "../../services/hooks/use-seo";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import { getBrandTranslation } from "./brands/brands";
import { getAboutTranslation } from "./about";
import { t } from "i18next";

export const getShippingTranslation = (label = "") =>
  t(`bybest.shippingMethod.${label}`);

const ByBestShippingMethod = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
            {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
            {">"} {getShippingTranslation("title")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <h3 className="text-[13px] leading-[21px] font-bold text-[#666] uppercase">
                {getShippingTranslation("subtitle")}
              </h3>
              <p className="mt-[5px] text-[13px] leading-[20px] text-[#000] ">
                {getShippingTranslation("deliveryDay")}{" "}
                <span className="text-[#666]">
                  {getShippingTranslation("url")}
                </span>{" "}
                {getShippingTranslation("notResponsible")}
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000]">
                  {getShippingTranslation("bigForce")}
                </li>
                <li className="mt-[6px] text-[12px] leading-[20px] text-[#666] ">
                  {getShippingTranslation("transport")}
                </li>
              </ul>
              <p className="mt-[13px] text-[13px] leading-[20px] text-[#000] ">
                {getShippingTranslation("deliveryAddress")}
              </p>
              <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getShippingTranslation("deliveryStandart")}
              </h3>
              <p className="mt-2 text-[13px] leading-[25px] text-[#000] ">
                <span className="text-[#666]">
                  {getShippingTranslation("deliveryCost")}
                </span>
                {getShippingTranslation("deliveryOutside")}{" "}
                <span className="text-[#666]">
                  {getShippingTranslation("transportCost")}
                </span>
                {getShippingTranslation("transportOutside")}{" "}
                <span className="text-[#666]">
                  {getShippingTranslation("ourMan")}
                </span>
              </p>
              <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getShippingTranslation("deliveryGifts")}
              </h3>
              <p className="mt-2 text-[13px] leading-[25px] text-[#000] ">
                {getShippingTranslation("buyer")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestShippingMethod;
