import React, { useState } from "react";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import ChoosePayment from "./choose-payment";
import { cashDeliveryIcon } from "./icons";

const PaymentMethod = () => {
  const [selectedPayment, setSelectedPayment] = useState(
    customerText("cashDelivery")
  );

  const handlePaymentSelect = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="text-[#101828] text-18 font-semibold leading-7">
        {customerText("paymentMethod")}
      </div>
      <div className="grid lg:flex items-center gap-4 cash-delivery">
        <ChoosePayment
          icon={cashDeliveryIcon}
          borderColor={
            selectedPayment === customerText("cashDelivery")
              ? "border-[#E14848]"
              : "border-[#677489]"
          }
          description={customerText("default")}
          title={customerText("cashDelivery")}
          isSelected={selectedPayment === customerText("cashDelivery")}
          onSelect={handlePaymentSelect}
        />
        <ChoosePayment
          icon={
            <img
              alt="bkt"
              src="https://www.bkt.com.al/assets/uploads/logo.svg"
              className="max-w-[40px]"
            />
          }
          borderColor={
            selectedPayment === customerText("payWithCard")
              ? "border-[#E14848]"
              : "border-[#677489]"
          }
          description="BKT"
          title={customerText("payWithCard")}
          isSelected={selectedPayment === customerText("payWithCard")}
          onSelect={handlePaymentSelect}
        />
      </div>
    </div>
  );
};

export default PaymentMethod;
