import { customerText } from "../../components/Seo/whitelabel/bybest/bybest-customer-layout";

export const filterOptions = [
  { label: customerText("allOrders"), value: "all" },
  { label: customerText("pending"), value: "pending" },
  { label: customerText("shipped"), value: "shipped" },
  { label: customerText("delivered"), value: "delivered" },
  { label: customerText("confirmed"), value: "confirmed" },
  { label: customerText("cancelled"), value: "cancelled" },
];
