import React, { Suspense, useEffect } from "react";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import BBHeader from "../header/BBHeader";
import BybestTopCarousel from "../../components/BybestTopCarousel";
import moment from "moment";
import CookieConsent from "react-cookie-consent";
import ScrollToTop from "../../components/Hooks/scrolltoTop";
import ScrollToTopButtonByBest from "../../components/ScrollToUpButton/scroll-to-top-by-best";
import { useTranslation } from "react-i18next";

// Lazy load the Footer component
const LazyFooter = React.lazy(() => import("../footer/BBFooter"));

const BBGuestLayout = () => {
  const { pathname } = useLocation();
  const { app_key = "BYB2802APPY" } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const isHomepage = pathname === "";

    let newPath;
    if (isHomepage) {
      newPath = "";
    }

    if (newPath && pathname !== newPath) {
      const queryString = searchParams.toString();
      const newFullPath = queryString ? `${newPath}?${queryString}` : newPath;
      
      navigate(newFullPath, { replace: true });
    }
  }, [pathname, navigate, app_key]);

  return (
    <>
      <ScrollToTopButtonByBest />
      <BybestTopCarousel coupon_end_data={moment(new Date()).add(5, "days")} />
      <BBHeader />
      <Outlet />
      <Suspense fallback={<div>Loading footer...</div>}>
        <LazyFooter />
      </Suspense>

      <CookieConsent
        location="bottom"
        buttonText={t("bybest.cookieConsent.buttonText")}
        cookieName="venueboost-bybest-cookie"
        style={{ background: "#fff", color: "#000" }}
        containerClasses={"!px-[20px] md:!px-[50px]"}
        contentClasses="text-center md:text-left"
        buttonWrapperClasses="w-full md:w-auto"
        buttonClasses=" !m-0 w-full md:w-auto !bg-[#000] !text-[#fff] !text-[13px] !whitespace-nowrap !rounded-[5px] !px-[20px] !h-[42px]"
        expires={150}
      >
        {t("bybest.cookieConsent.message")}{" "}
        <a href="/page/cookies" className=" text-[#eec430]" target="_blank">
          {t("bybest.cookieConsent.learnMore")}
        </a>
      </CookieConsent>
      <NotificationContainer />
      <ScrollToTop />
    </>
  );
};

export default BBGuestLayout;
