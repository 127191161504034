import React from "react";
import BybestCustomerLayout, {
  customerText,
} from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerHeader from "../customer-header";
import "./index.css";
import PaymentMethod from "./payment-method";
import ReferralsTable from "./referrals-table";
import WalletPoints from "./wallet-points";
import WalletTable from "./wallet-table";

const Wallet = () => {
  return (
    <BybestCustomerLayout selected="wallet" key="wallet">
      <div className="flex flex-1 flex-col gap-5 ">
        <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg mt-6">
          <div className="px-6 py-[10px] flex">
            <CustomerHeader
              title={customerText("wallet")}
              description={customerText("walletDescription")}
              showItems={false}
            />
          </div>

          <div className="grid lg:grid-cols-3 grid-cols-1">
            <div className="col-span-2 border p-2 px-6">
              <PaymentMethod />
            </div>
            <WalletPoints />
          </div>
        </div>
        <WalletTable />
        <ReferralsTable />
      </div>
    </BybestCustomerLayout>
  );
};

export default Wallet;
