import React, { useState } from "react";
import "./index.css";
import { FavoriteBorderOutlined } from "@mui/icons-material";
import { MdFavorite } from "react-icons/md";

const BybestProductItem = ({
  data,
  type,
  onClick = () => {},
  onFavoriteChange = () => {},
  onShoppingcartChange = () => {},
  className = "",
}) => {
  const [visibleSecond, setVisibleSecond] = useState(false);
  return (
    <div
      className={`cursor-pointer flex w-full flex-col rounded-[5px] relative ${className}`}
    >
      <div
        onClick={onClick}
        className="w-full h-[180px] md:h-[300px] rounded-[5px] relative"
        onMouseEnter={() => setVisibleSecond(true)}
        onMouseLeave={() => setVisibleSecond(false)}
      >
        <img
          alt=""
          className="absolute border top-0 z-[1] bg-white left-0 w-full h-[180px] md:h-[300px] rounded-[5px] object-cover"
          src={data?.img1}
        />
        <img
          alt=""
          className={
            "absolute border top-0 left-0 w-full h-[180px] md:h-[300px] rounded-[5px] object-cover " +
            (visibleSecond ? " z-[2]" : "")
          }
          src={data?.img2}
        />
      </div>
      {type === "home" ? (
        <div
          className="flex flex-col justify-start items-start py-3"
          onClick={onClick}
        >
          <p
            style={{ lineHeight: "21px;" }}
            className="text-[15px] font-bold text-[#000] lineClamp2"
          >
            {data.title}
          </p>
          <p
            style={{ lineHeight: "21px;" }}
            className="text-[12px] font-medium text-[#000] lineClamp1"
          >
            {data.category}
          </p>
          <p
            style={{ lineHeight: "21px;" }}
            className="text-[15px] font-bold text-[#000]"
          >
            LEK {data.price}
          </p>
        </div>
      ) : type === "similar" ? (
        <div className="data" onClick={onClick}>
          <div className="title truncate">{data.title}</div>

          <div className="sub-title truncate">
            <p>
              {data.category} <br />
            </p>
          </div>

          <div className="price-details flex gap-1 items-center">
            <span className="old-price">LEK {data?.price}</span>
            <span className="discount">-{data?.discount}</span>
            <span className="new-price">LEK {data?.sale_price}</span>
          </div>
        </div>
      ) : (
        <div className="flex justify-start items-center text-[15px] px-1 py-3">
          <span className="old-price relative">LEK {data?.price} </span>
          <span className=" px-2"> -{data?.discount} </span>
          <span className="text-[#f00]"> LEK {data?.sale_price}</span>
        </div>
      )}

      {data?.is_best_seller && (
        <div className="absolute top-4 left-4 z-[3]">
          <img
            style={{
              width: 40,
              display: "block",
              background: "none",
            }}
            src="https://admin.bybest.shop/storage/upploads/by_best_image_0_1701910517.png"
            alt=""
          />
        </div>
      )}

      <div className="absolute top-4 right-4 z-[3]" onClick={()=> onFavoriteChange(data)}>
        {data.favorite ? (
          <MdFavorite style={{ color: "black", fontSize: 24, fill: "black" }} />
        ) : (
          <FavoriteBorderOutlined style={{ color: "#000", fontSize: 24 }} />
        )}
      </div>
      <div className="absolute top-4 left-4 z-[3] text-[12px] ">
        {data.new && <span>E RE</span>}
      </div>
    </div>
  );
};

export default BybestProductItem;
