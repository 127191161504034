import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BBMainLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../../services/hooks/use-seo";
import { t } from "i18next";
import { getBrandTranslation } from "../brands/brands";

export const getTrackTranslation = (label = "") =>
  t(`bybest.trackOrder.${label}`);

const ByBestOrderTracking = () => {
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  const [tracking_number, setTrackingNumber] = useState("");

  const onSubmit = () => {
    navigate(`/tracking/${tracking_number}`);
  };

  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase ">
            {getBrandTranslation("breadcrumb")} {getTrackTranslation("title")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="mt-5 w-full px-4 flex justify-center items-center rounded-[3px] bg-[#f2f2f2] p-[30px]">
            <input
              type="search"
              placeholder={getTrackTranslation("trackCode")}
              className="flex-1 h-[48px] p-[10px] rounded-[5px] border-[2px] border-[#000] !outline-none !focus:ring-0"
              onChange={(value) => setTrackingNumber(value.target.value)}
            />
            <button
              disabled={tracking_number.length === 0}
              className={`ml-2 rounded-[5px] bg-[#000] h-[48px]  font-bold text-[14px] text-white uppercase ${
                tracking_number.length === 0
                  ? "cursor-not-allowed"
                  : ""
              }`}
              onClick={onSubmit}
            >
              {getTrackTranslation("trackOrder")}
            </button>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestOrderTracking;
