import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { getBrandTranslation } from "./brands/brands";
import { getAboutTranslation } from "./about";
import { t } from "i18next";

export const getFaqTranslation = (label = "") => t(`bybest.faqs.${label}`);

const ByBestFaqs = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="uppercase leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
            {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
            {`>`}
            {getFaqTranslation("faq")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <h3 className="text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                {getFaqTranslation("faq")}
              </h3>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    {getFaqTranslation("contactService")}
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    {getFaqTranslation("availableToHelp")}
                    <br />
                    {getFaqTranslation("clientDetails")}
                    <br />
                    {getFaqTranslation("telephone")}: +355 68 805 2000
                    <br />
                    {getFaqTranslation("clientService")}{" "}
                    <span className=" underline">
                      customerservice@bybest.shop
                    </span>
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000]">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    {getFaqTranslation("authenticate")}
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]">
                    {getFaqTranslation("authorizedSeller")}
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000]">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    {getFaqTranslation("canAddSelledArticles")}
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    {getFaqTranslation("addedArticles")}
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000]">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    {getFaqTranslation("whenMyOrderShip")}
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    {getFaqTranslation("orderShip")}
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                  <p className="uppercase text-[13px] leading-[25px]  text-[#000] font-bold">
                    {getFaqTranslation("costAsk")}
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000] ">
                    {getFaqTranslation("costChanges")}{" "}
                    <span className="underline uppercase">
                      {getFaqTranslation("deliveryAndPayment")}
                    </span>
                    {getFaqTranslation("deliveryInfo")}
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    {getFaqTranslation("cancelOrderAsk")}
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    {getFaqTranslation("cancelOrderExplanation")}
                    <br />
                    {getFaqTranslation("requestCancellation")}
                    <br />
                    {getFaqTranslation("cancelButton")}
                    <br />
                    {getFaqTranslation("contactForMoreInfo")}
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    {getFaqTranslation("notifyAboutPromotions")}
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    {getFaqTranslation("registerAt")}{" "}
                    <b className="cursor-pointer">
                      {getFaqTranslation("endOfPage")}
                    </b>{" "}
                    {getFaqTranslation("subscribeForEmails")}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestFaqs;
