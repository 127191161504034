import apiFactory from "../utils/apiFactory";
import {
  API_ByBest
} from "../constants/apiConfig";

export default class BbService {
  static getHomePageData = async (venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_ByBest.path}/home`,
      query: `venue_app_key=${venue_app_key}`,
      method: "GET",
    };
    return apiFactory(api);
  };
  static getHeaderMenu = async (venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_ByBest.path}/menus`,
      query: `venue_app_key=${venue_app_key}`,
      method: "GET",
    };
    return apiFactory(api);
  };
}
