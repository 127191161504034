import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import img404 from "../../assets/images/404new.png";
import { Seo } from "../../components/Seo";
import "./index.css";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname !== "/not-found") {
      navigate("/not-found");
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center not-found m-auto full-width mt-16 py-10 md:py-[76px]">
      <Seo title={"Page Not Found | VenueBoost"} />
      <img className="md:mb-10 " src={img404} alt="" />
      <h1 className="mt-5 mb-5 text-20 md:text-[32px] text-[#240b3b] leading-[120%] font-extrabold">
        Page Not Found
      </h1>
      <h1 className="md:w-[50%] text-center w-full mb-10 text-18 md:text-20 text-[#3C3C43D9] leading-[140%] font-medium">
        Oops! It looks like you've taken a wrong turn. The page you're looking
        for isn't here.
      </h1>
      <div className="flex flex-col md:flex-row gap-4 md:gap-6">
        <a
          className="rounded-[8px] px-[48px] text-white text-16 font-extrabold leading-[120%] h-[48px] md:h-[64px] bg-[#240b3b] align-middle"
          href="/"
        >
          Back to home
        </a>
        <a
          className="rounded-[8px] px-[48px] bg-white text-16 font-extrabold leading-[120%] h-[48px] md:h-[64px] text-[#240b3b] border-[1px] border-[#240b3b] align-middle"
          href="https://support.venueboost.io/hc/en-us"
        >
          Contact support
        </a>
      </div>
    </div>
  );
};

export default NotFound;
