import React from "react";
import "./index.css";

const PromotionSummary = ({ title = "", description = "", promo = "" }) => {
  return (
    <div className="flex justify-between items-center mt-5 pb-[15px]">
      <div className="flex flex-col gap-1">
        <p className="promo-title">{title}</p>
        <p className="promo-description">{description}</p>
      </div>
      <div className="promo-wrapper">
        <span>{promo}</span>
      </div>
    </div>
  );
};

export default PromotionSummary;
