import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import CustomerHeader from "../customer-header";
import CustomerFooterButtons from "../customer-footer-buttons";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const ChangePassword = () => {
  const [data, setData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  const handleChange = (e) => {
    setData((info) => ({ ...info, [e.target.name]: e.target.value }));
  };
  return (
    <div>
      <CustomerHeader
        title={customerText("loginSecurityTitle")}
        description={customerText("lastPasswordUpdate")}
        showItems={false}
        showHome={false}
      />
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />

      <div className="w-full flex flex-col md:flex-row items-center mt-6 md:mt-0">
        <div className="w-full md:w-[30%] ">
          <p className="text-14 text-[#374151] font-semibold leading-5">
            {customerText("currentPassword")}
          </p>
        </div>
        <div className="w-full md:w-[70%]">
          <div className="w-full md:w-[50%] h-[38px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
            <input
              type={type}
              name="currentPassword"
              value={data?.currentPassword || ""}
              onChange={handleChange}
              autoComplete="current-password"
              className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
            />
            <span
              className="flex justify-around items-center cursor-pointer"
              style={{ color: "#CBD5E1" }}
              onClick={handleToggle}
            >
              <Icon className="absolute " icon={icon} size={18} />
            </span>
          </div>
        </div>
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />

      <div className="w-full flex flex-col md:flex-row items-center mt-6 md:mt-0">
        <div className="w-full md:w-[30%] ">
          <p className="text-14 text-[#374151] font-semibold leading-5">
            {customerText("newPassword")}
          </p>
        </div>
        <div className="w-full md:w-[70%]">
          <div className="w-full md:w-[50%] h-[38px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
            <input
              type="text"
              name="newPassword"
              value={data?.newPassword || ""}
              onChange={handleChange}
              autoComplete="new-password"
              className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
            />
          </div>
        </div>
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />

      <div className="w-full flex flex-col md:flex-row items-center mt-6 md:mt-0">
        <div className="w-full md:w-[30%] ">
          <p className="text-14 text-[#374151] font-semibold leading-5">
            {customerText("confirmPassword")}
          </p>
        </div>
        <div className="w-full md:w-[70%]">
          <div className="w-full md:w-[50%] h-[38px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
            <input
              type="text"
              name="confirmPassword"
              value={data?.confirmPassword || ""}
              onChange={handleChange}
              className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
            />
          </div>
        </div>
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />

      <CustomerFooterButtons />
    </div>
  );
};

export default ChangePassword;
