import React, { useState } from "react";
import CustomerHeader from "../customer-header";
import { referralsColumns, referralsData } from "./columns";
import GenericTable from "../../../components/Table/generic-table";
import { referralIcon } from "./icons";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const ReferralsTable = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    total: referralsData.length,
  });

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
  };

  return (
    <div className="flex flex-1 flex-col mt-6">
      <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
        <div className="flex justify-between items-center w-full p-6">
          <CustomerHeader
            description={customerText("orderKeepTrack")}
            numberOfItems={referralsData.length}
            items={customerText("total")}
            title={customerText("referrals")}
            showHome={false}
            showReferalCode={true}
            wallet={{
              title: customerText("referralCode"),
              description: "BYBEST85444",
              showCopy: true,
              icon: referralIcon,
              className: "gap-1",
            }}
          />
        </div>
        <GenericTable
          columns={referralsColumns}
          data={referralsData.slice(
            (pagination.current - 1) * 10,
            pagination.current * 10
          )}
          className="w-full hidden md:block pr-4"
          pagination={{
            ...pagination,
            onChange: handlePageChange,
          }}
        />
      </div>
    </div>
  );
};

export default ReferralsTable;
