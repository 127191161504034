import React from "react";
import { customerText } from "../../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import "./index.css";

const Subtotal = () => {
  const data = [
    { label: customerText("subtotal"), value: "8150.00 LEK" },
    { label: customerText("shippingCost"), value: "8150.00 LEK" },
    { label: customerText("discount"), value: "8150.00 LEK" },
  ];

  return (
    <div className="flex flex-col gap-2 bg-white">
      {data.map((item, index) => (
        <div className="subtotal-wrapper" key={index}>
          <span>{item.label}</span>
          <p>{item.value}</p>
        </div>
      ))}
    </div>
  );
};

export default Subtotal;
