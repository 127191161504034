import React from "react";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import { xIcon } from "./icons";
import "./index.css";

const WishlistItems = ({ items = [] }) => {
  return items.map((item, index) => (
    <div
      key={index}
      className="wishlist-item flex flex-row items-center gap-2 lg:gap-5 relative border"
    >
      <div className="image h-full w-full">
        <img src={item.image} alt={item.title} className="h-full w-full" />
      </div>

      <div className=" h-full flex flex-col gap-2 w-full justify-between description">
        <div className="w-full wishlist-details">
          <div className="absolute right-2 top-2 cursor-pointer">
            <span>{xIcon}</span>
          </div>
          <div className="flex flex-col gap-5">
            <div>
              <span className="h3">{item.title}</span>
              {item.collection && <p>{item.collection}</p>}
              <p>
                {customerText("brand")}: {item.brand}
              </p>
              {item.gender && <p>Gender: {item.gender}</p>}
              {item.mechanism && <p>Mechanism: {item.mechanism}</p>}
            </div>
            <span className="price uppercase">
              {customerText("orderColumns.price")}:{" "}
              <strong>{item.price} LEKE</strong>
            </span>
          </div>
        </div>

        <div className="button">
          <button>
            <span>{customerText("addToCart")}</span>
          </button>
        </div>
      </div>
    </div>
  ));
};

export default WishlistItems;
