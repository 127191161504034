import React, { useEffect, useRef } from "react";

const BktPaymentForm = ({ paymentUrl, paymentData }) => {
  const formRef = useRef(null);

  useEffect(() => {
    // Auto-submit the form on component mount
    formRef.current.submit();
  }, []);

  return (
    <div>
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.slim.js"
        integrity="sha512-HNbo1d4BaJjXh+/e6q4enTyezg5wiXvY3p/9Vzb20NIvkJghZxhzaXeffbdJuuZSxFhJP87ORPadwmU9aN3wSA=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      ></script>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          stroke="#32c682"
          width="80px"
          height="80px"
          transform="scale(.8)"
          viewBox="0 0 38 38"
        >
          <g
            fill="none"
            fillRule="evenodd"
            strokeWidth="2"
            transform="translate(1 1)"
          >
            <circle cx="18" cy="18" r="18" strokeOpacity=".25"></circle>
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                dur="1s"
                from="0 18 18"
                repeatCount="indefinite"
                to="360 18 18"
                type="rotate"
              ></animateTransform>
            </path>
          </g>
        </svg>
      </div>

      <form ref={formRef} action={paymentUrl} method="post">
        {Object.entries(paymentData).map(([key, value]) => (
          <div key={key} style={{ display: "none" }}>
            <label htmlFor={key} style={{ width: "10%" }}>
              {key}
            </label>
            <input
              type="text"
              id={key}
              name={key}
              value={value}
              style={{ width: "50%" }}
              readOnly
            />
          </div>
        ))}
        <button type="submit" style={{ display: "none" }}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default BktPaymentForm;
