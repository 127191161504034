import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { MdOutlineClose } from "react-icons/md";
import "./index.css";
import "react-phone-input-2/lib/style.css";
import * as yup from "yup";
import { useFormik } from "formik";
import SvgCard from "../../assets/svgs/bybest/card.svg";
import SvgCash from "../../assets/svgs/bybest/cash.svg";
import { PaymentMethod } from "../../views/bybest/cart-paymentmethod";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import { getBrandTranslation } from "../../views/bybest/brands/brands";
import BktPaymentForm from "../../views/bybest/bkt-payment/BktPaymentForm";
import BBCheckoutForm from "./bb-checkout-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCartTranslation } from "../../views/bybest/cart/cart-products";

export const validator = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required("")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  last_name: yup
    .string()
    .trim()
    .required("")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  email: yup
    .string()
    .trim()
    .required("")
    .max(72, "Max exceeded")
    .min(5, "Min not met")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  phone: yup
    .string()
    .trim()
    .required("")
    .max(72, "Max exceeded")
    .min(5, "Min not met"),
  country: yup.string().trim().required(""),
  city: yup.string().trim().required(""),
  address: yup.string().trim().required(""),
});

const BybestCheckoutModal = ({ app_key, product,errorParam, showModal, onClose }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [showWrong, setShowWrong] = useState(errorParam ?? false);

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const isLocalhost = window.location.hostname === "localhost";

  useEffect(() => setOpen(showModal), [showModal]);

  const formikProps = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      country: null,
      city: null,
      address: "",
    },
    validationSchema: validator,
    onSubmit: (values) => onSubmit(values),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const paymentMethods = [
    {
      id: "cash",
      img: SvgCash,
      title: "Para ne Dore",
      desc: "Paguaj ne dorezim",
    },
    {
      id: "card",
      img: SvgCard,
      title: "Credit/Debit Card",
      desc: "Paguaj online nepermjet...",
    },
  ];
  const [curPaymentMethod, setCurPaymentMethod] = useState("cash");

  const [paymentInfo, setPaymentInfo] = useState(null);

  const onSubmit = (values) => {
    if (!recaptchaToken && !isLocalhost) {
      NotificationManager.error(
        "Please complete the reCAPTCHA to submit the form.",
        "Error",
        3000
      );
      return;
    } else {
      WhiteLabelService.checkoutOrderBybest({
        ...values,
        payment_method: curPaymentMethod,
        token: recaptchaToken,
        order_products: [{ product_id: product.id, product_quantity: 1 }],
        app_key,
      })
        .then((res) => {
          if(curPaymentMethod === "cash"){
            navigate("/cart/success");
          }else {
            setPaymentInfo(res);
          }
        })
        .catch((error) => {
          setShowWrong(true);
          NotificationManager.error(
            error.message ||
              "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
            "Error",
            3000
          );
        });
    }
  };

  if (paymentInfo) {
    return (
      <BktPaymentForm
        paymentUrl={paymentInfo.payment_url}
        paymentData={paymentInfo.payment_data}
      />
    );
  }

  return (
    <Modal
      centered
      closeIcon={false}
      footer={null}
      wrapClassName="bybest-checkoutmodal"
      open={open}
      onCancel={onClose}
    >
      <div className="flex flex-col justify-center items-center w-full">
        <div className="flex justify-center items-center w-full py-3 px-4 border-b border-b-[#DEE2E6]">
          <h1 className="flex-1 text-[20px] font-bold leading-[30px] text-[#000]">
            {getBrandTranslation("buyWithOneClick")}
          </h1>
          <MdOutlineClose size={22} onClick={onClose} />
        </div>
        <div className="w-full p-4">
          <div className="w-full flex justify-center items-center rounded-[5px] p-3 bg-[#3F51B512] border border-[#3F51B5]">
            <img
              alt=""
              src={product.img1}
              className="bg-white w-[70px] h-[80px] rounded-[4px] mr-2"
            />
            <div className="flex-1 ">
              <h3 className=" text-[15px] font-bold leading-[19px] text-[#000]">
                {product.title}
              </h3>
              <p className="text-[13px] leading-[16px] text-[#000]">
                {product.category}
              </p>
              <p className=" mt-3 text-[13px] leading-[16px] text-[#000]">
                {getBrandTranslation("articleNumber")} {product.no}
              </p>
            </div>
          </div>

          {showWrong && (
            <div className="error-message w-full mt-4 rounded-[5px]">
              <span>{getCartTranslation("wentWrong")}</span>
            </div>
          )}

          <BBCheckoutForm {...{ formikProps }} />

          <div className="mt-4 w-full">
            <p className="text-[12px] leading-[18px] text-[#000]  mb-1">
              {getBrandTranslation("paymentMethods")}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-3">
              {paymentMethods.map((pm, index) => (
                <PaymentMethod
                  key={index}
                  data={pm}
                  isSelected={pm.id === curPaymentMethod}
                  onSelect={(p) => setCurPaymentMethod(p.id)}
                />
              ))}
            </div>
          </div>
          <div className="mt-4 w-full rouned-[4px] p-4 bg-[#F2F2F2]">
            <div className="w-full flex justify-center items-center">
              <input
                name="coupon"
                placeholder={getBrandTranslation("couponCode")}
                className="flex-1 h-[45px] px-3 border-[#767676] border-[2px] rounded-[4px] mr-4"
              />
              <button className="uppercase h-[45px] px-8 bg-[#000] rounded-[4px] text-white text-[14px] font-bold">
                {getBrandTranslation("apply")}
              </button>
            </div>
            <div className="mt-2 w-full flex justify-between items-start">
              <h3 className="font-bold text-[16px] text-[#000] ">Total</h3>
              <div className="flex flex-col justify-end items-end">
                <h3 className="font-bold text-[16px] text-[#000] ">
                  LEK {product?.sale_price}
                </h3>
                <p className="font-medium text-[13px] text-[#000] ">
                  {getBrandTranslation("reward")}{" "}
                  {getBrandTranslation("points")}
                </p>
              </div>
            </div>
          </div>
          <ReCAPTCHA
            sitekey="6Lct7x4lAAAAAFpOMTljbGFY7hqdJnHOvhWvHKhb"
            onChange={handleRecaptchaChange}
            className="mt-4"
          />
          <button
            className="mt-4 w-full h-[41px] rounded-[4px] bg-[#000000a5] text-white text-[15px] font-semibold"
            onClick={formikProps.handleSubmit}
          >
            {getBrandTranslation("orderNow")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BybestCheckoutModal;
