import React from "react";
import { Pagination } from "antd";
import "./index.css";
import DropdownFilter from "../Dropdown/dropdown-filter";
import { t } from "i18next";

const GenericPagination = ({
  current = 1,
  total = 1,
  onChange,
  pageSize = 10,
}) => {
  const numberOfPages = Math.ceil(total / pageSize);

  const handlePrevious = () => {
    if (current > 1) {
      onChange(current - 1);
    }
  };

  const handleNext = () => {
    if (current < numberOfPages) {
      onChange(current + 1);
    }
  };

  return (
    <div className="grid justify-center items-center md:flex md:justify-between gap-2 p-2 md:p-6">
      <div className="pagination-text flex justify-center md:justify-start">
        {t("table.page")} {current} {t("table.of")} {numberOfPages}
      </div>
      <Pagination
        current={current}
        total={total}
        onChange={onChange}
        pageSize={pageSize}
        showSizeChanger={false}
        hideOnSinglePage={false}
      />
      <div className="flex items-center gap-2 justify-center md:justify-start">
        <DropdownFilter
          showIcon={false}
          title={t("table.previous")}
          onClick={handlePrevious}
          isSelect={false}
        />
        <DropdownFilter
          showIcon={false}
          title={t("table.next")}
          onClick={handleNext}
          isSelect={false}
        />
      </div>
    </div>
  );
};

export default GenericPagination;
