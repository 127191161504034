export const whitelabelSeoConfigs = {
    bybest: {
        bodyScripts: {
            start: {
                matomo: true,
                matomoTagManager: true,
            },
            end: {
                zendesk: 'adafc6d2-4d22-426a-8865-aff99314a7a4',
                hotjar: {
                    hjid: 3100742,
                    hjsv: 6
                },
                googleAnalytics: 'G-LXWDW67GKK'
            }
        }
    },
};

export const getWhitelabelSeoClientConfig = (clientKey) => {
    return whitelabelSeoConfigs[clientKey] || whitelabelSeoConfigs.bybest; // Default to ByBest if client not found
};