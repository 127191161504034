import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { useTranslation } from "react-i18next";

const ByBestHelp = () => {
  const { pageSEO } = useSeoData("https://bybest.shop/help");
  const { t } = useTranslation();

  const getTranslation = (label = "") => t(`bybest.${label}`);

  const socialMedia = [
    {
      platform: "facebook",
      url: "https://www.facebook.com/bybestshop-105726465473349/",
    },
    {
      platform: "instagram",
      url: "https://www.instagram.com/bybest.shop/",
    },
    { platform: "twitter", url: "https://twitter.com/ByBestShop" },
    {
      platform: "pinterest",
      url: "https://www.pinterest.com/bybestshop/_saved/",
    },
    { platform: "tiktok", url: "https://www.tiktok.com/@bybestshop" },
    {
      platform: "linkedin",
      url: "https://www.linkedin.com/in/bybest-shop-6b819523a/",
    },
  ];

  const helpSections = [
    {
      heading: "help.clientService",
      content: `${getTranslation("help.phoneLabel")} +355 69 356 1111`,
    },
    {
      heading: "help.clientService",
      content: `${getTranslation(
        "help.emailLabel"
      )} customerservice@bybest.shop`,
    },
    {
      heading: "help.productInfo",
      content: `${getTranslation(
        "help.emailLabel"
      )} customerservice@bybest.shop`,
    },
    {
      heading: "help.salesOffice",
      content: `${getTranslation("help.emailLabel")} marketing@bybest.shop`,
    },
    {
      heading: "help.socialMedia",
      content: (
        <ul className="list-disc pl-5">
          {socialMedia.map(({ platform, url }) => (
            <li key={platform}>
              <a
                rel="noreferrer"
                className="text-[12px] leading-[20px] text-[#000] underline"
                href={url}
                target="_blank"
              >
                {getTranslation(`help.${platform}`)}
              </a>
            </li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase">
            {getTranslation("help.breadcrumb")}
          </p>
        </div>
        <div className="w-full max-w-screen-main mt-7 mb-5">
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className="col-span-5">
              {helpSections.map((section, index) => (
                <div className="grid grid-cols-1 md:grid-cols-3" key={index}>
                  <div className="col-span-1">
                    <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                      {getTranslation(section.heading)}
                    </h3>
                  </div>
                  <div className="md:col-span-2 mt-[13px] text-[12px] leading-[20px] text-[#000]">
                    {typeof section.content === "string" ? (
                      <span className="underline">{section.content}</span>
                    ) : (
                      section.content
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-span-2"></div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestHelp;
