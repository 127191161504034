import { t } from "i18next";
import ImgMenu1 from "../../assets/images/bybest/menus/menu_1.jpg";
import ImgMenu2 from "../../assets/images/bybest/menus/menu_2.jpg";
import ImgMenu3 from "../../assets/images/bybest/menus/menu_3.jpg";
import ImgMenu4 from "../../assets/images/bybest/menus/menu_4.jpg";
import ImgMenu5 from "../../assets/images/bybest/menus/menu_5.png";

const getTranslation = (label = "") => t(`bybest.${label}`);

export const lastOrders = [
  {
    id: 1307,
    name: "Montauk",
    subTitle: "Wine goblet large",
    oldPrice: "LEK 2,000",
    discount: "-20%",
    newPrice: "LEK 1,600",
    link: "/products/1307/montauk",
    images: [
      "https://admin.bybest.shop/storage/products/product_feature_1645561735.jpg",
      "https://admin.bybest.shop/storage/products/product_feature_0_1645561735.jpg",
    ],
    slug: "montauk",
  },
  {
    id: 3889,
    name: "Maxima Champagne flute",
    subTitle: "Special glass for champagne on special occasions",
    oldPrice: "LEK 3,000",
    discount: "-20%",
    newPrice: "LEK 2,400",
    link: "/products/3889/maxima-champagne-flute",
    images: [
      "https://admin.bybest.shop/storage/products/62c69da2e10af_1137310072_on_fs-m-1.jpg",
      "https://admin.bybest.shop/storage/products/62c69da4679f0_1137310072_1137316004_1137317872_on_use-1.jpg",
    ],
    slug: "maxima-champagne-flute",
  },
  {
    id: 3888,
    name: "Maxima red wine goblet",
    subTitle: "Special glass for fruity red wines",
    oldPrice: "LEK 3,000",
    discount: "-20%",
    newPrice: "LEK 2,400",
    link: "/products/3888/maxima-red-wine-goblet",
    images: [
      "https://admin.bybest.shop/storage/products/62c69c2941ac2_1137310011_on_fs-m-1 (1).jpg",
      "https://admin.bybest.shop/storage/products/62c69c2b3714b_1137310011_1137310011_1137310072_1137317872_1137316004_on_coll-1.jpg",
    ],
    slug: "maxima-red-wine-goblet",
  },
  {
    id: 3886,
    name: "Flow SALAD-SET 3PCS",
    subTitle: "The ideal salad set for lunch and picnics",
    oldPrice: "LEK 14,000",
    discount: "-30%",
    newPrice: "LEK 9,800",
    link: "/products/3886/flow-salad-set-3pcs-",
    images: [
      "https://admin.bybest.shop/storage/products/62c69aee3a117_1034208813_1034209076_on_fs-1.jpg",
      "https://admin.bybest.shop/storage/products/62c69af01f653_1034208813_1034209076_on_fs-1.jpg",
    ],
    slug: "flow-salad-set-3pcs-",
  },
];

export const menuItems = [
  {
    label: getTranslation("headerDrawer.menuItems.men1.label"),
    menus: [
      {
        category: getTranslation(
          "headerDrawer.menuItems.men1.menus.cat1.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men1.menus.cat1.items.item1"),
          getTranslation("headerDrawer.menuItems.men1.menus.cat1.items.item2"),
          getTranslation("headerDrawer.menuItems.men1.menus.cat1.items.item3"),
        ],
      },
      {
        category: getTranslation(
          "headerDrawer.menuItems.men1.menus.cat2.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men1.menus.cat2.items.item1"),
          getTranslation("headerDrawer.menuItems.men1.menus.cat2.items.item2"),
        ],
      },
    ],
    menu_image: ImgMenu1,
  },
  {
    label: getTranslation("headerDrawer.menuItems.men2.label"),
    menus: [
      {
        category: getTranslation(
          "headerDrawer.menuItems.men2.menus.cat1.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men2.menus.cat1.items.item1"),
          getTranslation("headerDrawer.menuItems.men2.menus.cat1.items.item2"),
          getTranslation("headerDrawer.menuItems.men2.menus.cat1.items.item3"),
        ],
      },
      {
        category: getTranslation(
          "headerDrawer.menuItems.men2.menus.cat2.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men2.menus.cat2.items.item1"),
          getTranslation("headerDrawer.menuItems.men2.menus.cat2.items.item2"),
        ],
      },
      {
        category: getTranslation(
          "headerDrawer.menuItems.men2.menus.cat3.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men2.menus.cat3.items.item1"),
          getTranslation("headerDrawer.menuItems.men2.menus.cat3.items.item2"),
          getTranslation("headerDrawer.menuItems.men2.menus.cat3.items.item3"),
        ],
      },
    ],
    menu_image: ImgMenu2,
  },
  {
    label: getTranslation("headerDrawer.menuItems.men3.label"),
    menus: [
      {
        category: getTranslation(
          "headerDrawer.menuItems.men3.menus.cat1.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item1"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item2"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item3"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item4"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item5"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item6"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item7"),
        ],
      },
      {
        category: getTranslation(
          "headerDrawer.menuItems.men3.menus.cat2.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men3.menus.cat2.items.item1"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat2.items.item2"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat2.items.item3"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat2.items.item4"),
        ],
      },
    ],
    menu_image: ImgMenu3,
  },
  {
    label: getTranslation("headerDrawer.menuItems.men4.label"),
    menus: [
      {
        label: getTranslation("headerDrawer.menuItems.men4.menus.item1.label"),
        link: "blog",
      },
      {
        label: getTranslation("headerDrawer.menuItems.men4.menus.item2.label"),
        link: "event",
      },
      {
        label: getTranslation("headerDrawer.menuItems.men4.menus.item3.label"),
        link: "event",
      },
    ],
  },
  {
    label: getTranslation("headerDrawer.menuItems.men5.label"),
    menus: [
      {
        category: getTranslation(
          "headerDrawer.menuItems.men5.menus.cat1.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men5.menus.cat1.items.item1"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat1.items.item2"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat1.items.item3"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat1.items.item4"),
        ],
      },
      {
        category: getTranslation(
          "headerDrawer.menuItems.men5.menus.cat2.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men5.menus.cat2.items.item1"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat2.items.item2"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat2.items.item3"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat2.items.item4"),
        ],
      },
    ],
    menu_image: ImgMenu4,
  },
  {
    id: 10,
    label: getTranslation("headerDrawer.menuItems.men6.label"),
    menus: [
      {
        category: getTranslation(
          "headerDrawer.menuItems.men6.menus.cat1.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item1"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item2"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item3"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item4"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item5"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item6"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item7"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item8"),
        ],
      },
    ],
    menu_image: ImgMenu5,
  },
  {
    label: getTranslation("headerDrawer.menuItems.men9.label"),
    menus: [
      {
        label: getTranslation("headerDrawer.menuItems.men9.menus.item1.label"),
      },
      {
        label: getTranslation("headerDrawer.menuItems.men9.menus.item2.label"),
      },
    ],
  },
  {
    id: 11,
    label: getTranslation("headerDrawer.menuItems.men7.label"),
    menus: [],
  },
];

export const menu_children = [
  {
    text: { en: "Gifts for Her", sq: "Gifts for Her" },
    link: "/category/gifts-for-her",
  },
  {
    text: { en: "Gifts for Him", sq: "Gifts for Him" },
    link: "/category/gifts-for-him",
  },
  {
    text: { en: "Gifts for Kids", sq: "Gifts for Kids" },
    link: "/category/gifts-for-kids",
  },
];
