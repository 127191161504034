import React from "react";
import SvgCartWhite from "../../assets/svgs/bybest/cart_white.svg";
import SvgCheckcircle from "../../assets/svgs/bybest/check_circle.svg";
import SvgPersonBlack from "../../assets/svgs/bybest/person_black.svg";
import SvgPersonWhite from "../../assets/svgs/bybest/person_white.svg";
import SvgWalletBlack from "../../assets/svgs/bybest/wallet_black.svg";
import SvgWalletWhite from "../../assets/svgs/bybest/wallet_white.svg";
import "./index.css";
import { getCartTranslation } from "../../views/bybest/cart/cart-products";

const BybestCartStepper = ({ curStep = 0 }) => {
  return (
    <div className="w-full h-[48px] grid grid-cols-3">
      <div
        className={`p-2 h-[48px] rounded-tl-[4px] rounded-bl-[4px] flex justify-start items-center ${
          curStep === 0 ? "bg-[#19CD6C]" : "bg-[#040B2E]"
        }`}
      >
        <img alt="" src={curStep === 0 ? SvgCartWhite : SvgCheckcircle} />
        <div className="ml-2 max-w-[50px] sm:max-w-full">
          <h3 className="text-[13px] text-white leading-[16px] truncate md:whitespace-normal">
            {getCartTranslation("shoppingBasket")}
          </h3>
          <p className="text-[11px] text-white leading-[14px] truncate md:whitespace-normal">
            {getCartTranslation("chooseProducts")}
          </p>
        </div>
      </div>
      <div
        className={`flex h-[48px] justify-start items-center ${
          curStep === 0
            ? "bg-[#E8E8E8]"
            : curStep === 1
            ? "bg-[#19CD6C]"
            : "bg-[#092147]"
        }`}
      >
        <div
          className={`bbcart-stepper-anchorright ${
            curStep === 0 ? "border-l-[#19CD6C]" : "border-l-[#040B2E]"
          }`}
        />
        <div className={`p-2 h-[48px] flex justify-start items-center flex-1`}>
          <img
            alt=""
            src={
              curStep === 0
                ? SvgPersonBlack
                : curStep === 1
                ? SvgPersonWhite
                : SvgCheckcircle
            }
          />
          <div className="ml-2 max-w-[50px] sm:max-w-full">
            <h3
              className={`text-[13px] truncate md:whitespace-normal ${
                curStep === 0 ? "text-[#000]" : "text-white"
              } leading-[16px]`}
            >
              {getCartTranslation("data")}
            </h3>
            <p
              className={`text-[11px] truncate md:whitespace-normal ${
                curStep === 0 ? "text-[#7B7E7F]" : "text-white"
              } leading-[14px]`}
            >
              {getCartTranslation("yourData")}
            </p>
          </div>
        </div>
      </div>
      <div
        className={`flex h-[48px] rounded-tr-[4px] rounded-br-[4px] justify-start items-center ${
          curStep < 2 ? "bg-[#F2F2F2]" : "bg-[#19CD6C]"
        }`}
      >
        <div
          className={`bbcart-stepper-anchorright ${
            curStep === 0
              ? "border-l-[#E8E8E8]"
              : curStep === 1
              ? "border-l-[#19CD6C]"
              : "border-l-[#092147]"
          }`}
        />
        <div
          className={`col-span-1 h-[48px] p-2 flex-1 flex justify-start items-center`}
        >
          <img alt="" src={curStep < 2 ? SvgWalletBlack : SvgWalletWhite} />
          <div className="ml-2 max-w-[50px] sm:max-w-full">
            <h3
              className={`text-[13px] truncate md:whitespace-normal ${
                curStep < 2 ? "text-[#000]" : "text-white"
              } leading-[16px]`}
            >
              {getCartTranslation("payments")}
            </h3>
            <p
              className={`text-[11px] truncate md:whitespace-normal ${
                curStep < 2 ? "text-[#7B7E7F]" : "text-white"
              } leading-[14px]`}
            >
              {getCartTranslation("endOrder")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BybestCartStepper;
