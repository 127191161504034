import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { t } from "i18next";
import { getBrandTranslation } from "./brands/brands";
import { getAboutTranslation } from "./about";

export const getReturnTranslation = (label = "") =>
  t(`bybest.returnPolicy.${label}`);

const ByBestReturnPolicy = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="uppercase leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
            {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
            {">"} {getReturnTranslation("title")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <p className="text-[13px] leading-[20px] text-[#000] ">
                {getReturnTranslation("topPriority")}
              </p>
              <p className="mt-[13px] text-[13px] leading-[20px] text-[#000] ">
                {getReturnTranslation("changeOrder")}
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  {getReturnTranslation("originalProduct")}
                </li>
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  {getReturnTranslation("costageCost")}
                </li>
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  {getReturnTranslation("customerService")}{" "}
                  <a className="underline">customerservice@bybest.shop</a>{" "}
                  {getReturnTranslation("switch")}:
                </li>
              </ul>
              <p className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                Email:{" "}
                <span className="underline">customerservice@bybest.shop</span>
              </p>
              <p className="text-[13px] leading-[20px] text-[#000] ">
                {getReturnTranslation("phone")}:{" "}
                <span className="underline">+355 68 805 2000</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestReturnPolicy;
