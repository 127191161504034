import React, { useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import BybestCustomerLayout, {
  customerText,
} from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import GenericTable from "../../../components/Table/generic-table";
import CustomerHeader from "../customer-header";
import { columns, dataSource } from "./columns";
import "./index.css";
import PromotionCalendar from "./promotion-calendar";

const initialPagination = {
  current: 1,
  pageSize: 5,
  total: dataSource.length,
};

const Promotions = () => {
  const [currentPromotion, setCurrentPromotion] = useState(initialPagination);

  const [usedPromotion, setUsedPromotion] = useState(initialPagination);

  const handleCurrentPageChange = (page, pageSize) => {
    setCurrentPromotion((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const handleUsedPageChange = (page, pageSize) => {
    setUsedPromotion((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  return (
    <BybestCustomerLayout selected="promotions" key="promotions">
      <div className="bg-white w-full grid lg:grid-cols-3 grid-cols-1 gap-4 mt-6">
        <div className="col-span-2 w-full border rounded-[12px]">
          <div className="flex justify-between items-center w-full p-6">
            <CustomerHeader
              description={customerText("promotionsKeepTrack")}
              numberOfItems={dataSource.length}
              items={customerText("promotions")}
              title={customerText("currentPromotions")}
            />
          </div>
          <GenericTable
            columns={columns}
            data={dataSource.slice(
              (currentPromotion.current - 1) * currentPromotion.pageSize,
              currentPromotion.current * currentPromotion.pageSize
            )}
            className="w-full md:block pr-4"
            pagination={{
              ...currentPromotion,
              onChange: handleCurrentPageChange,
            }}
          />
        </div>

        <PromotionCalendar />

        <div className="col-span-2 w-full border rounded-[12px]">
          <div className="flex justify-between items-center w-full p-6">
            <CustomerHeader
              description={customerText("promotionsKeepTrack")}
              numberOfItems={dataSource.length}
              items={customerText("promotions")}
              title={customerText("usedPromotions")}
            />
          </div>
          <GenericTable
            columns={columns}
            data={dataSource.slice(
              (usedPromotion.current - 1) * usedPromotion.pageSize,
              usedPromotion.current * usedPromotion.pageSize
            )}
            className="w-full md:block pr-4"
            pagination={{
              ...usedPromotion,
              onChange: handleUsedPageChange,
            }}
          />
        </div>
      </div>
    </BybestCustomerLayout>
  );
};

export default Promotions;
