import React from "react";
import { Link } from "react-router-dom";

const BybestBlogItem = ({ data }) => {
  return (
    <Link to={data?.slug} >
      <div className="flex flex-col rounded-[5px]">
        <img
          className="w-full h-[160px] md:h-[250px] object-cover"
          src={data?.image}
        />
        <p className="mt-[6px] w-full text-[#000] text-12 leading-[18px] ">{data?.category}</p>
        <p className=" mt-1 w-full text-[#000] text-[16px] leading-[20px] font-bold">{data?.title}</p>
        <p className=" mt-[6px] w-full text-[#000] text-[16px] leading-[20px] font-bold">Lexo me shume {'>'}</p>
      </div>
    </Link >
  );
};

export default BybestBlogItem;
