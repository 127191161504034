import React, { useEffect, useState } from "react";
import { getBrandTranslation } from "../../views/bybest/brands/brands";
import PhoneInput from "react-phone-input-2";
import { getRegisterConfig } from "../../redux/actions/contactsales";

const BBCheckoutForm = ({ formikProps }) => {
  const [cities, setCities] = useState([{ value: "", title: "Please select" }]);
  const [countries, setCountries] = useState([
    { value: "", title: "Please select" },
  ]);

  console.log(formikProps.errors, "formikProps");

  useEffect(() => {
    loadRegisterConfig();
  }, []);

  const loadRegisterConfig = () => {
    getRegisterConfig()
      .then((data) => {
        setCountries(
          data?.countries.map((country) => ({
            value: country.name,
            title: country.name,
            ...country,
          })) || []
        );

        const statesForSelectedCountry = [
          { value: "", title: "Please select" },
          ...data?.countries[0].states?.map((state) => ({
            value: state.name,
            title: state.name,
          })),
        ];
        setCities(statesForSelectedCountry);
      })
      .catch(() => {});
  };

  const handleChange = (e) => {
    formikProps.setFieldValue(e.target.name, e.target.value);

    if (e.target.name === "country") {
      const selectedCountry = countries.find(
        (country) => country.value === e.target.value
      );
      const statesForSelectedCountry =
        selectedCountry && selectedCountry.id
          ? [
              { value: "", title: "Please select" },
              ...selectedCountry.states?.map((state) => ({
                value: state.name,
                title: state.name,
              })),
            ]
          : [{ value: "", title: "Please select" }];

      setCities(statesForSelectedCountry);
    }
  };

  return (
    <form className="w-full">
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3">
        <div className="flex flex-col">
          <span className="text-[12px] leading-[18px] text-[#000]">
            {getBrandTranslation("name")}*{" "}
            <span className="italic">({getBrandTranslation("required")})</span>
          </span>
          <input
            name="first_name"
            placeholder={getBrandTranslation("name")}
            value={formikProps.values.first_name}
            onChange={handleChange}
            className={`mt-[2px] w-full h-[38px] px-5 border-[1px] ${
              formikProps.errors.first_name?.length === 0
                ? "border-[#dc3545]"
                : "border-[#198754]"
            } rounded-[4px]`}
          />
          <p className="text-[12px] text-[#f00]">
            {formikProps.errors.first_name}
          </p>
        </div>
        <div>
          <p className="text-[12px] leading-[18px] text-[#000]">
            {getBrandTranslation("surname")}{" "}
            <span className="italic">({getBrandTranslation("optional")})</span>
          </p>
          <input
            name="last_name"
            placeholder={getBrandTranslation("surname")}
            value={formikProps.values.last_name}
            onChange={handleChange}
            className={`mt-[2px] w-full h-[38px] px-5 border-[1px] ${
              formikProps.errors.last_name?.length === 0
                ? "border-[#dc3545]"
                : "border-[#198754]"
            } rounded-[4px]`}
          />
          <p className="text-[12px] text-[#f00]">
            {formikProps.errors.last_name}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3">
        <div>
          <p className="mt-3 text-[12px] leading-[18px] text-[#000]">
            Email{" "}
            <span className="italic">({getBrandTranslation("optional")})</span>
          </p>
          <input
            name="email"
            type="email"
            placeholder="Email"
            value={formikProps.values.email}
            onChange={handleChange}
            className={`mt-[2px] w-full h-[38px] px-5 border-[1px] ${
              formikProps.errors.email?.length === 0
                ? "border-[#dc3545]"
                : "border-[#198754]"
            } rounded-[4px]`}
          />
          <p className="text-[12px] text-[#f00]">{formikProps.errors.email}</p>
        </div>
        <div>
          <p className="mt-3 text-[12px] leading-[18px] text-[#000]">
            {getBrandTranslation("phone")}*{" "}
            <span className="italic">{getBrandTranslation("required")}</span>
          </p>
          <PhoneInput
            country={"al"}
            inputProps={{
              name: "phone",
              placeholder: "Phone",
            }}
            value={formikProps.values.phone}
            onChange={(v) => formikProps.setFieldValue("phone", v)}
            inputClass="!w-full !h-[38px] !border-[#198754] !border-[1px] !rounded-[4px]"
            containerClass="bb-checkout-phone !mt-[2px]"
          />
          <p className="text-[12px] text-[#f00]">{formikProps.errors.phone}</p>
        </div>
      </div>
      <p className="mt-4 w-full italic text-[11px] leading-[14px] text-[#000]">
        {getBrandTranslation("choosePrefix")}
        <br />
        {getBrandTranslation("numberStart")}
      </p>
      <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3">
        <div>
          <p className="text-[12px] leading-[18px] text-[#000]">Shteti</p>
          <select
            name="country"
            value={formikProps.values.country}
            onChange={handleChange}
            className={`mt-[2px] w-full h-[38px] px-5 border-[1px] ${
              formikProps.errors.country?.length === 0
                ? "border-[#dc3545]"
                : "border-[#198754]"
            } rounded-[4px]`}
          >
            {countries.map((item, index) => (
              <option key={index} value={item.value}>
                {item.title}
              </option>
            ))}
          </select>
          <p className="text-[12px] text-[#f00]">
            {formikProps.errors.country}
          </p>
        </div>
        <div>
          <p className="text-[12px] leading-[18px] text-[#000]">
            {getBrandTranslation("city")}
          </p>
          <select
            name="city"
            value={formikProps.values.city}
            onChange={handleChange}
            className={`mt-[2px] w-full h-[38px] px-5 border-[1px] ${
              formikProps.errors.city?.length === 0
                ? "border-[#dc3545]"
                : "border-[#198754]"
            } rounded-[4px]`}
          >
            {cities.map((item, index) => (
              <option key={index} value={item.value}>
                {item.title}
              </option>
            ))}
          </select>
          <p className="text-[12px] text-[#f00]">{formikProps.errors.city}</p>
        </div>
      </div>
      <p className="mt-3 text-[12px] leading-[18px] text-[#000]">
        {getBrandTranslation("address")}*{" "}
        <span className="italic">{getBrandTranslation("required")}</span>
      </p>
      <input
        name="address"
        placeholder={getBrandTranslation("address")}
        value={formikProps.values.address}
        onChange={handleChange}
        className={`mt-[2px] w-full h-[38px] px-5 border-[1px] ${
          formikProps.errors.address?.length === 0
            ? "border-[#dc3545]"
            : "border-[#198754]"
        } rounded-[4px]`}
      />
      <p className="text-[12px] text-[#f00]">{formikProps.errors.address}</p>
    </form>
  );
};

export default BBCheckoutForm;
