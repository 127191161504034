import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

export const loginLabels = [
  `${customerText(
    "loginOn"
  )} 3 July 2024 4:32 AM, IP 192.168.1.1 , ${customerText(
    "from"
  ).toLowerCase()} Windows 11 `,
  `${customerText("receivedEmail")} 3 July 2024 4:32 AM`,
  `${customerText("recentOrder")} 3 July 2024 4:32 AM`,
];
