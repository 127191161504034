import React, { useState, useEffect } from "react";
import BBMainLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../../services/hooks/use-seo";
import "./landing.css";
import RegisterMember from "./register-member";
import { t } from "i18next";
import NewBrandsSection from "./new-brands-section";
import LoyaltyProgram from "./loyalty-program";
import ReviewSection from "./review";
import FaqSection from "./faq-section";
import HeroSection from "../components/hero-section";
import heroImage from "../../../assets/images/landing/landing_hero.png";
import bbImage from "../../../assets/images/landing/bybest-image.png";
import { useNavigate } from "react-router-dom";
import WhiteLabelService from "../../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";

export const getTranslation = (label = "") => t(`bybest.landing.${label}`);

const Landing = () => {
  const { pageSEO } = useSeoData();
  const navigate = useNavigate();
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = (payload, app_key) => {
    const { full_name = "", email = "" } = payload;

    if (full_name.length === 0 || !full_name) {
      NotificationManager.error(
        `${getTranslation("nameLabel")} ${getTranslation("isRequired")}!`
      );
    } else if (email.length === 0 || !email) {
      NotificationManager.error(
        `${getTranslation("emailLabel")} ${getTranslation("isRequired")}!`
      );
    } else {
      setLoading(true);

      WhiteLabelService.registerLanding(payload, app_key)
        .then(() => {
          NotificationManager.success(
            "Registration completed successfully!",
            "Success",
            5000
          );
          setResetForm(true);
          setLoading(false);
        })
        .catch(() => {
          NotificationManager.error("Oops! Something went wrong!");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (resetForm) {
      setTimeout(() => {
        setResetForm(false);
      }, 100);
    }
  }, [resetForm]);

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-[#fafafa] landing-page-container mt-5 md:mt-2">
        <div className="w-full mb-5 flex flex-col items-center gap-8 px-5 xl:px-0">
          <div className="w-full max-w-screen-main">
            <HeroSection
              backgroundImage={heroImage}
              title={getTranslation("heroSection.title")}
              subtitle1={getTranslation("heroSection.subtitle1")}
              subtitle2={getTranslation("heroSection.subtitle2")}
              description={getTranslation("heroSection.description")}
              buttonText={getTranslation("heroSection.buttonText")}
              buttonAction={() => navigate(`/my-club`)}
            />
          </div>

          <div className="max-w-screen-main">
            <RegisterMember
              onSubmit={onSubmit}
              resetForm={resetForm}
              isLoading={loading}
            />
          </div>

          <hr className="mt-7" />
          <div className="max-w-screen-main">
            <NewBrandsSection />
          </div>
          <div className="max-w-screen-main">
            <LoyaltyProgram />
          </div>
          <div className="review-wrapper">
            <div className="max-w-screen-main w-full">
              <ReviewSection />
            </div>
          </div>
          <div className="max-w-screen-main">
            <FaqSection />
          </div>
          <div className="max-w-screen-main">
            <HeroSection
              backgroundImage={bbImage}
              subtitle1={getTranslation("finalcta.ready")}
              subtitle2={getTranslation("finalcta.partOfBybest")}
              description={getTranslation("finalcta.description")}
              buttonText={getTranslation("finalcta.btnlabel")}
              isHero={false}
            />
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default Landing;
