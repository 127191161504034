import React from "react";
import BBMainLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../../services/hooks/use-seo";
import { brandList } from "../data";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";

export const getBrandTranslation = (label = "") => t(`bybest.brands.${label}`);

const Brands = () => {
  const { pageSEO } = useSeoData();
  const { app_key = "BYB2802APPY" } = useParams();

  const navigate = useNavigate();

  const goToBrand = (url = "") => {
    navigate(`/brands/${url}`);
  };

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white brands">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase">
            {getBrandTranslation("breadcrumb")} {getBrandTranslation("title")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="grid xl:grid-cols-4 grid-cols-1 md:grid-cols-2 w-full items-center gap-4">
            {brandList.map((item, index) => {
              return (
                <div key={index} className="brand-card">
                  <div
                    onClick={() => goToBrand(item.url)}
                    className="brand-card-inner cursor-pointer"
                  >
                    <div className="icon">
                      <img src={item.src} alt="" />
                    </div>
                    <div className="name">
                      {`${item.name} (${
                        item.numOfProducts
                      } ${getBrandTranslation("products")})`}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default Brands;
