import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { useTranslation } from "react-i18next";

const ByBestVertualGiftCard = () => {
  const { t } = useTranslation();
  const { pageSEO } = useSeoData();

  const getTranslation = (label = "") => t(`bybest.virtualGiftCard.${label}`);

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
            {getTranslation("breadcrumb")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className="col-span-7">
              <h3 className="text-[16px] leading-[24px] font-bold text-[#000] uppercase">
                {getTranslation("title")}
              </h3>
              <p className="text-[13px] leading-[21px] text-[#000] ">
                {getTranslation("description")}
              </p>
              <div className="mt-5 w-full grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
                <div className="col-span-1 md:col-span-2">
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getTranslation("amountLabel")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    value={"€ 50"}
                    placeholder={getTranslation("amountLabel")}
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getTranslation("senderFirstNameLabel")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder={getTranslation("senderFirstNameLabel")}
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getTranslation("senderLastNameLabel")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder={getTranslation("senderLastNameLabel")}
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getTranslation("senderEmailLabel")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder={getTranslation("senderEmailLabel")}
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getTranslation("senderPhoneLabel")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder={getTranslation("senderPhoneLabel")}
                  />
                </div>
                <hr className="mt-1 col-span-1 md:col-span-2" />
                <div className="col-span-1 md:col-span-2">
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getTranslation("recipientFirstNameLabel")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder={getTranslation("recipientFirstNameLabel")}
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getTranslation("recipientEmailLabel")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder={getTranslation("recipientEmailLabel")}
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getTranslation("recipientConfirmEmailLabel")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder={getTranslation("recipientConfirmEmailLabel")}
                  />
                </div>
                <div className="col-span-1 md:col-span-2">
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getTranslation("messageLabel")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder={getTranslation("messageLabel")}
                  />
                </div>
              </div>
              <div className="mt-4 w-full flex justify-end items-end">
                <button className="px-8 h-[45px] rounded bg-[#000] text-white text-[16px] font-semibold">
                  {getTranslation("buyButton")}
                </button>
              </div>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  {getTranslation("note1")}
                </li>
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  {getTranslation("note2")}
                </li>
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  {getTranslation("note3")}
                </li>
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  {getTranslation("note4")}
                </li>
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  {getTranslation("note5")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestVertualGiftCard;
