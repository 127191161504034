import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaHeart, FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import cartIcon from "../../assets/svgs/bybest/bb-cart.svg";
import heartIcon from "../../assets/svgs/bybest/bb-heart.svg";
import headerIcon from "../../assets/svgs/bybest/bb-lang.svg";
import searchIcon from "../../assets/svgs/bybest/bb-search.svg";
import userIcon from "../../assets/svgs/bybest/bb-user.svg";
import BybestDropdownMenu from "../../components/BybestHeaderMenu";
import BbService from "../../services/BbService";
import { useLocalStorage } from "../../services/hooks/use-localstorage";
import { menu_children } from "./data";
import HeaderDrawer from "./header-drawer";
import "./index.css";
import MobileHeader from "./mobile-header";
import Sidebar from "../../components/Sidebar";
import { useMatchMedia } from "../../services/hooks/use-match-media";
import { changeLanguage } from "../../redux/actions/app";

const BBHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { app_key = "BYB2802APPY" } = useParams();
  const language = useSelector((state) => state.app.language);
  const [productList] = useLocalStorage("wishlist", []);
  const [cartList] = useLocalStorage("shoppingcart", []);
  const [open, setOpen] = useState(false);
  const [menus, setMenus] = useState([]);
  const [menuProducts, setMenuProducts] = useState([]);

  const isCustomer = pathname.includes("/customer/");
  const isMobile = useMatchMedia();

  useEffect(() => {
    loadHeaderMenu(app_key);
  }, [app_key]);

  useEffect(() => {
    if (!isMobile) setOpen(false);
  }, [isMobile]);

  const getTranslation = (label = "") => t(`bybest.header.${label}`);

  const onWishList = () => {
    navigate(`/wishlist`);
  };

  const onShoppingCart = () => {
    navigate(`/cart`);
  };

  const handleUserClick = () => {
    const token = localStorage.getItem("vbToken");
    if (token && token !== null) {
      navigate("/customer/orders");
    } else {
      navigate("/login");
    }
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    dispatch(changeLanguage(newLanguage));
    window.location.reload();
  };

  const headerIcons = [
    {
      to: `/my-club`,
      label: getTranslation("myClub"),
    },
    {
      to: `/tracking`,
      label: getTranslation("trackOrder"),
    },
    {
      onClick: handleUserClick,
      src: userIcon,
      desktopOnly: true,
    },
    {
      onClick: onWishList,
      src: heartIcon,
      icon: <FaHeart style={{ fontSize: 24 }} aria-hidden="true" />,
      badgeCount: productList.length,
    },
    {
      onClick: onShoppingCart,
      icon: <FaShoppingCart style={{ fontSize: 24 }} aria-hidden="true" />,
      src: cartIcon,
      badgeCount: cartList.length,
    },
  ];

  const loadHeaderMenu = (app_key) => {
    BbService.getHeaderMenu(app_key)
      .then((res) => {
        let resMenus = res.menus || [];
        resMenus.splice(3, 0, {
          title: { en: "Gifts", sq: "Gifts" },
          menu_children: [
            {
              text: { en: "Gifts for Her", sq: "Gifts for Her" },
              link: "/category/gifts-for-her",
            },
            {
              text: { en: "Gifts for Him", sq: "Gifts for Him" },
              link: "/category/gifts-for-him",
            },
            {
              text: { en: "Gifts for Kids", sq: "Gifts for Kids" },
              link: "/category/gifts-for-kids",
            },
          ],
        });
        resMenus.splice(6, 0, {
          title: { en: "BLOG", sq: "BLOG" },
          menu_children,
        });
        setMenus(resMenus);
        setMenuProducts(res.products || []);
      })
      .catch((error) => {
        console.log("loadHeaderMenu error ", error);
      });
  };

  return (
    <>
      <div className="flex justify-center items-center w-full bg-white z-10 top-[32px] fixed border-b border-b-[#F4F4F4]  px-4  ">
        <div className="w-full max-w-screen-main hidden md:flex justify-center items-center">
          <div className="flex flex-col pt-2 bg-white">
            <div className="bg-white flex justify-between">
              <div className="menu-header grid grid-cols-2">
                <div className="left-side gap-0 flex items-center">
                  <img alt="" src={headerIcon} />
                  <span className="lang cursor-pointer pl-2">
                    {getTranslation("languageLabel")}
                  </span>
                  <select
                    className="choose-lang cursor-pointer bg-transparent outline-none border-none"
                    value={language}
                    onChange={handleLanguageChange}
                  >
                    <option value="al">AL</option>
                    <option value="en">EN</option>
                  </select>
                  <select className="bold-label cursor-pointer bg-transparent outline-none border-none">
                    <option value="LEK">{getTranslation("currencyLek")}</option>
                  </select>
                </div>
              </div>
              <div className="flex items-center lg:justify-end justify-between w-full gap-2">
                {headerIcons.map((icon, index) => (
                  <div
                    key={index}
                    className={`my-club relative ${
                      icon.desktopOnly ? "hidden md:block" : ""
                    }`}
                    onClick={icon.onClick}
                  >
                    {icon.badgeCount > 0 && (
                      <span className="icon-absolute !text-white">
                        {icon.badgeCount}
                      </span>
                    )}
                    {icon.src ? (
                      <img alt="" src={icon.src} />
                    ) : (
                      icon.icon || (
                        <Link to={icon.to}>
                          <span>{icon.label}</span>
                        </Link>
                      )
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              <Link to="/">
                <img
                  alt="bybestlogo"
                  src="https://bybest.shop/assets/img/bybest-logo.png"
                  className="w-[211px] h-[52px] object-contain"
                />
              </Link>
            </div>
            <div className="w-full mt-3 px-5 xl:px-0">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-1" />
                <div className="flex justify-center items-center col-span-9 w-full">
                  <div className="flex items-center w-[100vw] overflow-hidden overflow-x-auto justify-center h-[44px]">
                    {menus.map((item, index) => (
                      <BybestDropdownMenu key={index} menu={item} />
                    ))}
                  </div>
                </div>
                <div className="relative w-full col-span-2 py-1 px-1">
                  <div className="flex justify-end border-b border-b-[#333333] py-1 pb-1 px-1">
                    <span className="absolute left-0 inset-y-0 flex items-center h-[40px]">
                      <img alt="" src={searchIcon} />
                    </span>
                    <input
                      autoComplete="off"
                      type="search"
                      placeholder={getTranslation("searchPlaceholder")}
                      className="pl-5 pr-2 w-full border-none outline-none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:flex md:h-[164px]" />

      <MobileHeader {...{ open, setOpen, headerIcons }} />

      <div className="flex md:hidden h-[80px] pt-2" />
      {isCustomer && isMobile ? (
        <Sidebar
          {...{ setOpen, open }}
          selected={pathname.replace("/customer/", "")}
        />
      ) : (
        <HeaderDrawer {...{ open }} onClose={() => setOpen(false)} />
      )}
    </>
  );
};

export default BBHeader;
