import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { getBrandTranslation } from "./brands/brands";
import { t } from "i18next";
import { getAboutTranslation } from "./about";

export const getContactTranslation = (label = "") =>
  t(`bybest.contact.${label}`);

const ByBestContact = () => {
  const { pageSEO } = useSeoData();
  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
            {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
            {`>`} {getBrandTranslation("contactUs")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-5">
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1">
                  <h3 className="text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getContactTranslation("customerService")}
                  </h3>
                </div>
                <div className="md:col-span-2">
                  <p className="text-[13px] leading-[20px] text-[#000] uppercase">
                    Tel: <span className="underline">+355 69 356 1111</span>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1">
                  <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getContactTranslation("customerService")}
                  </h3>
                </div>
                <div className="md:col-span-2">
                  <p className="mt-[13px] text-[12px] leading-[20px] text-[#000] uppercase">
                    Email:{" "}
                    <span className="underline">
                      customerservice@bybest.shop
                    </span>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1">
                  <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getContactTranslation("productInfo")}
                  </h3>
                </div>
                <div className="md:col-span-2">
                  <p className="mt-[13px] text-[12px] leading-[20px] text-[#000] uppercase">
                    Email:{" "}
                    <span className="underline">
                      customerservice@bybest.shop
                    </span>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1">
                  <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getContactTranslation("salesOffice")}
                  </h3>
                </div>
                <div className="md:col-span-2">
                  <p className="mt-[13px] text-[12px] leading-[20px] text-[#000] uppercase">
                    Email:{" "}
                    <span className="underline">marketing@bybest.shop</span>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1">
                  <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getContactTranslation("socialNetworks")}
                  </h3>
                </div>
                <div className="md:col-span-2">
                  <ul className="mt-[13px] list-disc pl-5">
                    <li>
                      <a
                        rel="noreferrer"
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://www.facebook.com/bybestshop-105726465473349/"
                        target={"_blank"}
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://www.instagram.com/bybest.shop/"
                        target={"_blank"}
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://twitter.com/ByBestShop"
                        target={"_blank"}
                      >
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://www.pinterest.com/bybestshop/_saved/"
                        target={"_blank"}
                      >
                        Pinterest
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://www.tiktok.com/@bybestshop"
                        target={"_blank"}
                      >
                        TikTok
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://www.linkedin.com/in/bybest-shop-6b819523a/"
                        target={"_blank"}
                      >
                        Linkedin
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 mt-5">
                <div className="col-span-1">
                  <h3 className="text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getContactTranslation("admin")}
                  </h3>
                  <p className="text-[12px] leading-[21px] text-[#000] ">
                    Metropol – Qendra Tregtare, Rruga. “Sami Frashëri”,
                  </p>
                  <p className="text-[12px] leading-[21px] text-[#000] ">
                    Tiranë, Shqipëri
                  </p>
                </div>
                <div className="col-span-2">
                  <p className="text-[13px] leading-[20px] text-[#000] ">
                    Tel: <span className="underline">+355 69 356 1111</span>
                  </p>
                  <p className="mt-1 text-[12px] leading-[21px] text-[#000] ">
                    {getContactTranslation("openinghours")} 09:00-17:00{" "}
                  </p>
                  <p className="mt-1 text-[12px] leading-[21px] text-[#000] ">
                    {getContactTranslation("vat")}: K01621002N
                  </p>
                </div>
              </div>
            </div>
            <div className=" col-span-2"></div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestContact;
