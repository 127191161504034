import BlogImg1 from "../../assets/images/bybest/blogs/blog1.png";
import BlogImg2 from "../../assets/images/bybest/blogs/blog2.png";
import BlogImg3 from "../../assets/images/bybest/blogs/blog3.png";
import BlogImg4 from "../../assets/images/bybest/blogs/blog4.png";
import BlogImg5 from "../../assets/images/bybest/blogs/blog5.png";
import BlogImg6 from "../../assets/images/bybest/blogs/blog6.png";

export const home_products = [
  // {
  //   cat_img:
  //       "https://admin.bybest.shop/storage/brands/brands_sidebar_1725022722.jpg",
  //   products: [
  //     {
  //       id: 1,
  //       img1: "https://admin.bybest.shop/storage/products/667937cd7c08b_millenia-necklace--octagon-cut--color-gradient--blue--rhodium-plated-swarovski-5694136%20(1).avif",
  //       img2: "https://admin.bybest.shop/storage/products/667937c482c17_millenia-necklace--octagon-cut--color-gradient--blue--rhodium-plated-swarovski-5694136 (3).avif",
  //       price: 35500,
  //       title: "SPARKLING DAISY",
  //       category: "Octagon cut, Color gradient, Blue, Rhodium plated",
  //     },
  //     {
  //       id: 2,
  //       img1: "https://admin.bybest.shop/storage/products/669d2c22d52c6_millenia-open-ring--octagon-cut--blue--rhodium-plated-swarovski-5694138 (1).jpg",
  //       img2: "https://admin.bybest.shop/storage/products/669d2c2c3a0f6_millenia-open-ring--octagon-cut--blue--rhodium-plated-swarovski-5694138.jpg",
  //       price: 12000,
  //       title: "Millenia open ring",
  //       category: "Octagon cut, Blue, Rhodium plated",
  //     },
  //     {
  //       id: 3,
  //       img1: "https://admin.bybest.shop/storage/products/66a7df631ed19_millenia-drop-earrings--octagon-cut--color-gradient--blue--rhodium-plated-swarovski-5696516 (1).jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66a7df673d982_millenia-drop-earrings--octagon-cut--color-gradient--blue--rhodium-plated-swarovski-5696516.jpg",
  //       price: 16000,
  //       title: "Millenia drop earrings",
  //       category: "Octagon cut, Color gradient, Blue, Rhodium plated",
  //       is_best_seller: true,
  //     },
  //     {
  //       id: 4,
  //       img1: "https://admin.bybest.shop/storage/products/66a7d8addb6ef_sublima-drop-earrings--round-cut--medium--white--rhodium-plated-swarovski-5699564 (1).jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66a7d8b20e7d2_sublima-drop-earrings--round-cut--medium--white--rhodium-plated-swarovski-5699564 (5).jpg",
  //       price: 30000,
  //       title: "Sublima drop earrings",
  //       category: "Round cut, Medium, White, Rhodium plated",
  //     },
  //     {
  //       id: 5,
  //       img1: "https://admin.bybest.shop/storage/products/66bf3a4c7d9bc_sublima-cuff--crystal-pearl--round-cut--white--rhodium-plated-swarovski-5700924 (1).jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66bf3a4e7393f_sublima-cuff--crystal-pearl--round-cut--white--rhodium-plated-swarovski-5700924 (5).jpg",
  //       price: 35500,
  //       title: "Sublima cuff",
  //       category: "Crystal pearl, Round cut, White, Rhodium plated",
  //       is_best_seller: true,
  //     },
  //     {
  //       id: 6,
  //       img1: "https://admin.bybest.shop/storage/products/66bf3c54bde56_sublima-cocktail-ring--round-cut--white--rhodium-plated-swarovski-5702416 (4).jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66bf3c58630cd_sublima-cocktail-ring--round-cut--white--rhodium-plated-swarovski-5702416.jpg",
  //       price: 3599,
  //       title: "Sublima cocktail ring",
  //       category: "Round cut, White, Rhodium plated",
  //     },
  //   ],
  // },
  // {
  //   cat_img:
  //       "https://admin.bybest.shop/storage/brands/brands_sidebar_1722518010.png",
  //   products: [
  //     {
  //       id: 1,
  //       img1: "https://admin.bybest.shop/storage/products/669058b196491_SB05N116_sa200_er005.png",
  //       img2: "https://admin.bybest.shop/storage/products/669058b46e5e3_SB05N116_sa280_er003.webp",
  //       price: 14000,
  //       title: "COLORS OF JOY (Ø 47.00)",
  //       category: "NAVY JOY",
  //     },
  //     {
  //       id: 2,
  //       img1: "https://admin.bybest.shop/storage/products/669062924f9eb_SB05N113_sa200_er005.png",
  //       img2: "https://admin.bybest.shop/storage/products/669062969c59d_SB05N113_li2_ec001.avif",
  //       price: 12000,
  //       title: "INDIGO GLOW",
  //       category: "SWATCH ESSENTIALS (Ø 47.00)",
  //     },
  //     {
  //       id: 3,
  //       img1: "https://admin.bybest.shop/storage/products/6528167098f87_SB07S100G_sa200_er005.avif",
  //       img2: "https://admin.bybest.shop/storage/products/65281679b6181_SB07S100G_li1_ec001.avif",
  //       price: 23000,
  //       title: "AQUA SHIMMER",
  //       category: "BIG BOLD IRONY (Ø 47.00)",
  //     },
  //     {
  //       id: 4,
  //       img1: "https://admin.bybest.shop/storage/products/663deeb312bea_YVS528G_sa200_er005.avif",
  //       img2: "https://admin.bybest.shop/storage/products/663deeb5edcd3_YVS528G_sa000_er003.avif",
  //       price: 22500,
  //       title: "THAT'S SO PEACHY",
  //       category: "SWATCH ESSENTIALS",
  //     },
  //     {
  //       id: 5,
  //       img1: "https://admin.bybest.shop/storage/products/product_feature_1645368334.png",
  //       img2: "https://admin.bybest.shop/storage/products/product_feature_0_1645368334.png",
  //       price: 4100,
  //       title: "PUNTOLUCE AQUAMARINE",
  //       category: "Pendent",
  //     },
  //     {
  //       id: 6,
  //       img1: "https://admin.bybest.shop/storage/products/663de9cd0b154_SS07B112_sa200_er005.avif",
  //       img2: "https://admin.bybest.shop/storage/products/663de9d1a0ebf_SS07B112_li2_ec001.avif",
  //       price: 22500,
  //       title: "GREEN VISION",
  //       category: "SWATCH ESSENTIALS",
  //     },
  //   ],
  // },
  // {
  //   cat_img:
  //       "https://admin.bybest.shop/storage/brands/brands_sidebar_1725022873.jpg",
  //   products: [
  //     {
  //       id: 1,
  //       img1: "https://admin.bybest.shop/storage/products/66c88b9e110e8_summer-splash_zfcsp125_01.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66c88b9ad7bcb_summer-splash-zfcsp125_126_04.jpg",
  //       price: 6500,
  //       title: "PINK SPLASH",
  //       category: "Pink Splash Rubber Stap",
  //     },
  //     {
  //       id: 2,
  //       img1: "https://admin.bybest.shop/storage/products/66c88b140d843_summer-splash-gruen_zfcsp126_01.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66c88b2d677fb_summer-splash-zfcsp126_10.jpg",
  //       price: 6500,
  //       title: "BLUE SPLASH",
  //       category: "Summer Splash",
  //     },
  //     {
  //       id: 3,
  //       img1: "https://admin.bybest.shop/storage/products/66c88f57e3231_flik-flak-watch-faber-castel-color-in-pastel-only-time-purple-32mm-with-case.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66c88d2718247_watch-only-time-child-flik-flak-x-faber-castell-multicolour-zfbnp231_777411.avif",
  //       price: 6500,
  //       title: "Color in Pastel Only Time Purple",
  //       category:
  //           "Faber Castel Color in Pastel Only Time Purple 32mm with Case",
  //     },
  //     {
  //       id: 4,
  //       img1: "https://admin.bybest.shop/storage/products/66d1d7b913bcf_56534.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66c88c806674d_flik-flak-zfbnp232-motiv-zviratka_258502_380504.jpg",
  //       price: 6500,
  //       title: "Colour In Neon Fabric",
  //       category: "Flik Flak Children's Watch Set Color In Neon",
  //     },
  //     {
  //       id: 5,
  //       img1: "https://admin.bybest.shop/storage/products/63d2a5c568570_puckit.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/63d2a5c6998d0_puckit1.jpg",
  //       price: 6500,
  //       title: "PUCK IT",
  //       category: "(Ø 36.00)",
  //     },
  //     {
  //       id: 6,
  //       img1: "https://admin.bybest.shop/storage/products/654ffab57ff0f_ZFCSP122_fa000_ec002.png",
  //       img2: "https://admin.bybest.shop/storage/products/654ffab6db90e_ZFCSP122_li100_ec001.avif",
  //       price: 6500,
  //       title: "LEVEL TEAL",
  //       category: "(Ø 36.70)",
  //     },
  //   ],
  // },
  // {
  //   cat_img:
  //       "https://admin.bybest.shop/storage/brands/brands_sidebar_1712939381.jpg",
  //   products: [
  //     {
  //       id: 11,
  //       img1: "https://admin.bybest.shop/storage/products/624ad747559fe_1046552200_on_fs-m-1 (1).jpg",
  //       img2: "https://admin.bybest.shop/storage/products/624ad76e25bda_1046552200_on_mil-1 (1).jpg",
  //       price: 17000,
  //       title: "Avarua Cake Plate",
  //       category: "Tropical look with gold detailing",
  //     },
  //     {
  //       id: 12,
  //       img1: "https://admin.bybest.shop/storage/products/62fe570c435a1_1046552621_on_fs-m-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/62fe570d559de_1046552621_on_mil-1.jpg",
  //       price: 10494,
  //       title: "Avarua dinner plate",
  //       category: "Tropical look with gold detailing",
  //     },
  //     {
  //       id: 13,
  //       img1: "https://admin.bybest.shop/storage/products/629688cc8cf0b_1046567880_on_fs-m-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/629688d097937_1046567880_on_mil-1.jpg",
  //       price: 31000,
  //       title: "Avarua Gifts cake stand",
  //       category: "Tropical look with gold detailing",
  //     },
  //     {
  //       id: 14,
  //       img1: "https://admin.bybest.shop/storage/products/624acff2d3281_1046552620_on_fs-m-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/624acff4e4887_1046551260_1046551270_1046551280_1046552640_1046554856_1046552620_on_usp-d-1.jpg",
  //       price: 10000,
  //       title: "Avarua Dinner Plate",
  //       category: "Tropical look with gold detailing",
  //     },
  //     {
  //       id: 15,
  //       img1: "https://admin.bybest.shop/storage/products/629686cf21faf_1046553170_on_fs-m-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/629686d1d1481_1046551900_1046552535_1046553170_on_use-1.jpg",
  //       price: 19000,
  //       title: "Avarua, salad bowl",
  //       category: "Avarua salad bowl, 23 cm, white/multicoloured",
  //     },
  //     {
  //       id: 16,
  //       img1: "https://admin.bybest.shop/storage/products/624ad877e563e_1046550460_on_fs-m-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/624ad87a019fe_1046550460_on_fs-m-2.jpg",
  //       price: 35000,
  //       title: "Avarua Coffee/Teapot",
  //       category: "Tropical look with gold detailing",
  //     },
  //   ],
  // },
  // {
  //   cat_img:
  //       "https://admin.bybest.shop/storage/brands/brands_sidebar_1704487240.png",
  //   products: [
  //     {
  //       id: 10,
  //       img1: "https://admin.bybest.shop/storage/products/62caf62c2e5a8_1048699418_on_fs-m-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/62caf61cf1f1a_1048699418_on_mil-1.jpg",
  //       price: 6500,
  //       title: "To go& to stay, glass lunch Box L",
  //       category: "Sustainable pleasure: cool, versatile glass lunch box",
  //     },
  //     {
  //       id: 20,
  //       img1: "https://admin.bybest.shop/storage/products/62caf503b552e_1048699417_on_fs-m-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/62caf50c03c85_1048699417_on_mil-1.jpg",
  //       price: 5000,
  //       title: "To go& to stay, glass lunch Box M",
  //       category: "Sustainable pleasure: cool, versatile glass lunch box",
  //     },
  //     {
  //       id: 30,
  //       img1: "https://admin.bybest.shop/storage/products/6425b3a94b341_1048699414_on_fs-m-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/6425b3aaa0394_1048699418_1048699411_1048699417_1048699415_1048699414_on_usp-f-1.jpg",
  //       price: 6000,
  //       title: "ToGo&ToStay lunch box M rectangular",
  //       category: "Sustainable pleasure: cool, versatile ceramic lunch box",
  //     },
  //     {
  //       id: 40,
  //       img1: "https://admin.bybest.shop/storage/products/65414d0282383_1486713840_on_fs-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/65414d05d1c58_1486713840_on_fs-2.jpg",
  //       price: 12000,
  //       title: "Winter Glow cake stand",
  //       category: "Porcelain serving aid with winter pattern",
  //     },
  //     {
  //       id: 50,
  //       img1: "https://admin.bybest.shop/storage/products/654153d161053_1486715110_on_fs-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/654153dc49d64_1486715110_on_use-1.jpg",
  //       price: 12000,
  //       title: "Winter Glow vase",
  //       category: "Porcelain vase with winter pattern",
  //     },
  //     {
  //       id: 60,
  //       img1: "https://admin.bybest.shop/storage/products/65414f78a2ce0_1486713968_on_fs-1.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/65414f7ec8ec8_1486713968_on_mil-1.jpg",
  //       price: 3000,
  //       title: "Winter Glow candleholder donut",
  //       category: "Porcelain candleholder with winter pattern",
  //     },
  //   ],
  // },
  // {
  //   cat_img:
  //       "https://admin.bybest.shop/storage/brands/brands_sidebar_1704487281.png",
  //   products: [
  //     {
  //       id: 31,
  //       img1: "https://admin.bybest.shop/storage/products/623b82231fd32_00675607.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/623b82204fb45_00675607.jpg",
  //       price: 17000,
  //       title: "Loft 150 x 250",
  //       category: "Tablecloth",
  //     },
  //     {
  //       id: 22,
  //       img1: "https://admin.bybest.shop/storage/products/654cd616259f0_64a2b93ca4f37_Valencia-MD_600x600.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/64a2b93868637_Valencia-Vorschauc9ymKubT2NLwY_600x600.jpg",
  //       price: 15500,
  //       title: "Valencia",
  //       category: "Tablecloth",
  //     },
  //     {
  //       id: 23,
  //       img1: "https://admin.bybest.shop/storage/products/623b6eb22792a_01434004.jfif",
  //       img2: "https://admin.bybest.shop/storage/products/623b6eb09b54d_01434004.jfif",
  //       price: 5000,
  //       title: "Anna 50 x 140",
  //       category: "Table Runner",
  //     },
  //     {
  //       id: 24,
  //       img1: "https://admin.bybest.shop/storage/products/623b802a5d173_00675601.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/623b802780a71_00675601.jpg",
  //       price: 17000,
  //       title: "Loft 150 x 250",
  //       category: "Tablecloth",
  //     },
  //     {
  //       id: 25,
  //       img1: "https://admin.bybest.shop/storage/products/624ab9c5cf95b_26b1ca769cf672c6fbd5f81c3c70b146586a3efd-obrus-bistro-alvor-150-x-250-cm-sander.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/624ab9c365226_26b1ca769cf672c6fbd5f81c3c70b146586a3efd-obrus-bistro-alvor-150-x-250-cm-sander.jpg",
  //       price: 17000,
  //       title: "Bistro Alvor 150 x 250",
  //       category: "",
  //     },
  //     {
  //       id: 26,
  //       img1: "https://admin.bybest.shop/storage/products/623b65ada7cc4_01365440.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/623b65ac87de1_01365440.jpg",
  //       price: 15000,
  //       title: "Poppy Meadow 32 x 48",
  //       category: "Placemat",
  //     },
  //   ],
  // },
  // {
  //   cat_img:
  //       "https://admin.bybest.shop/storage/brands/brands_sidebar_1661764889.png",
  //   products: [
  //     {
  //       id: 41,
  //       img1: "https://admin.bybest.shop/storage/products/product_feature_1646153861.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/product_feature_0_1646153861.jpg",
  //       price: 2000,
  //       title: "LOFT square biscuits box with bamboo lid Q.B.",
  //       category: "LOFT square biscuits box with bamboo lid Q.B.",
  //     },
  //     {
  //       id: 42,
  //       img1: "https://admin.bybest.shop/storage/products/6247345a0665f_LFTC03QB101.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/6247345d28b59_LFTC03QB101.jpg",
  //       price: 17000,
  //       title: "LOFT square sugar canister with bamboo lid Q.B.",
  //       category: "LOFT square sugar canister with bamboo lid Q.B.",
  //     },
  //     {
  //       id: 43,
  //       img1: "https://admin.bybest.shop/storage/products/62448b2a9d6b8_LFTC04QB101.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/62448b267d833_LFTC04QB101.jpg",
  //       price: 17000,
  //       title: "LOFT square coffee canister with bamboo lid Q.B.",
  //       category: "LOFT square coffee canister with bamboo lid Q.B.",
  //     },
  //     {
  //       id: 44,
  //       img1: "https://admin.bybest.shop/storage/products/62448351039f6_LFTC09QB101.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/624483539e44e_LFTC09QB101.jpg",
  //       price: 3000,
  //       title: "LOFT cylindric potatoes box with bamboo lid Q.B.",
  //       category: "LOFT cylindric potatoes box with bamboo lid Q.B.",
  //     },
  //     {
  //       id: 45,
  //       img1: "https://admin.bybest.shop/storage/products/6244831a19011_LFTC08QB101.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/6244831f1f74e_LFTC08QB101.jpg",
  //       price: 1100,
  //       title: "LOFT cylinder garlic-onion box with bamboo lid Q.B.",
  //       category: "LOFT cylinder garlic-onion box with bamboo lid Q.B.",
  //     },
  //     {
  //       id: 46,
  //       img1: "https://admin.bybest.shop/storage/products/6244827c55ba7_LFTC06QB101.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/6244827edf32c_LFTC06QB101.jpg",
  //       price: 1700,
  //       title: "LOFT rectangular teabag box with bamboo lid Q.B.",
  //       category: "LOFT rectangular teabag box with bamboo lid Q.B.",
  //     },
  //   ],
  // },
  // {
  //   cat_img:
  //       "https://admin.bybest.shop/storage/brands/brands_sidebar_1722351316.png",
  //   products: [
  //     {
  //       id: 51,
  //       img1: "https://admin.bybest.shop/storage/products/66b2515c34950_006156336_UFSTILL.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66b25159ef780_006156336_UFSTILL.jpg",
  //       price: 3959,
  //       title: "Set velours con rouches neonata",
  //       category: "Completini",
  //     },
  //     {
  //       id: 52,
  //       img1: "https://admin.bybest.shop/storage/products/66b2367e91c1d_006156245_UFSTILL.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66b2367c77a34_006156245_UFSTILL.jpg",
  //       price: 3629,
  //       title: "Velour baby romper with embroidery",
  //       category: "Overalls",
  //     },
  //     {
  //       id: 53,
  //       img1: "https://admin.bybest.shop/storage/products/66b231d0c20ef_006156180_UFSTILL.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66b231ca05e02_006156180_UFSTILL.jpg",
  //       price: 4289,
  //       title: "Cardigan con cappuccio in misto cotone neonato",
  //       category: "Maglioncini",
  //     },
  //     {
  //       id: 54,
  //       img1: "https://admin.bybest.shop/storage/products/66b230fa130a5_006156177_UFSTILL.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66b230f5f27f7_006156177_UFSTILL.jpg",
  //       price: 4289,
  //       title: "Baby girl cotton blend hooded cardigan",
  //       category: "Sweaters",
  //     },
  //     {
  //       id: 55,
  //       img1: "https://admin.bybest.shop/storage/products/66b22f0814617_006156094_UFSTILL.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66b22f05457bb_006156094_UFSTILL.jpg",
  //       price: 3629,
  //       title: "Tutina in misto cotone neonato",
  //       category: "Tutine",
  //     },
  //     {
  //       id: 56,
  //       img1: "https://admin.bybest.shop/storage/products/66b22c6d00f87_006156091_UFSTILL.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66b22c6d00f87_006156091_UFSTILL.jpg",
  //       price: 3599,
  //       title: "Tutina in misto cotone neonata",
  //       category: "Tutine",
  //     },
  //   ],
  // },
  // {
  //   cat_img:
  //       "https://admin.bybest.shop/storage/brands/brands_sidebar_1700057871.png",
  //   products: [
  //     {
  //       id: 61,
  //       img1: "https://admin.bybest.shop/storage/products/66378be223c83_12971446.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/66378bde3e5c0_12971447.jpg",
  //       price: 5609,
  //       title: "IANA pure cotton palazzo trousers for girls",
  //       category: "TROUSERS",
  //     },
  //     {
  //       id: 62,
  //       img1: "https://admin.bybest.shop/storage/products/6637826fae747_12971432.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/6637827871358_12971432.jpg",
  //       price: 3959,
  //       title: "IANA pure cotton striped top for girls",
  //       category: "GIRLS'S SWEATERS",
  //     },
  //     {
  //       id: 63,
  //       img1: "https://admin.bybest.shop/storage/products/660b0a12381fd_12945261.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/660b0a1394cec_12945260.jpg",
  //       price: 6203,
  //       title: "3-7 YEARS'GIRLS JACKETS",
  //       category: "FORMAL JACKET",
  //     },
  //     {
  //       id: 64,
  //       img1: "https://admin.bybest.shop/storage/products/660b08452ff38_12895362.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/660b08469fcf1_12895458.jpg",
  //       price: 5174,
  //       title: "3-7 YEARS GIRLS'SKIRTS",
  //       category: "SKIRT",
  //     },
  //     {
  //       id: 65,
  //       img1: "https://admin.bybest.shop/storage/products/65f744ad6b46c_12910191.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/65f744afc89a3_12910192.jpg",
  //       price: 5147,
  //       title: "GIRLS' LONG/SHORT TROUSERS",
  //       category: "SHORTS",
  //     },
  //     {
  //       id: 66,
  //       img1: "https://admin.bybest.shop/storage/products/64da61a04b261_12622014.jpg",
  //       img2: "https://admin.bybest.shop/storage/products/64da61a62c040_12622015.jpg",
  //       price: 3899,
  //       title: "BABY SWEATERS",
  //       category: "SHIRT",
  //     },
  //   ],
  // },
];

export const blogs = [
  {
    id: 1,
    category: "#villeroy&boch",
    title: "Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!",
    slug: "uljet-net-swarivski",
    content: "",
    date: "29-07-2024",
    image: BlogImg1,
  },
  {
    id: 2,
    category: "#villeroy&boch",
    title: "Ide dhuratash nga Swatch për një verë të",
    slug: "ide-dhuratash",
    content: "",
    date: "29-07-2024",
    image: BlogImg2,
  },
  {
    id: 3,
    category: "#villeroy&boch",
    title: "Ide dhuratash nga Villeroy&Boch: Eleganca që lë",
    slug: "ide-eleganca",
    content: "",
    date: "29-07-2024",
    image: BlogImg3,
  },
  {
    id: 4,
    category: "#villeroy&boch",
    title: "Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!",
    slug: "shfrytezoni",
    content: "",
    date: "29-07-2024",
    image: BlogImg4,
  },
  {
    id: 5,
    category: "#villeroy&boch",
    title: "Eja merr ç'të duhet! 60% ULJE në produkte të selektuara Blukids",
    slug: "eja-merr",
    content: "",
    date: "29-07-2024",
    image: BlogImg5,
  },
  {
    id: 6,
    category: "#villeroy&boch",
    title: "Ditët e nxehta me 40% ULJE në produktet e Villeroy&Boch",
    slug: "ditet-e-nxehta",
    content: "",
    date: "29-07-2024",
    image: BlogImg6,
  },
  {
    id: 7,
    category: "#villeroy&boch",
    title: "Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!",
    slug: "shfry-ofertat",
    content: "",
    date: "29-07-2024",
    image: BlogImg2,
  },
  {
    id: 8,
    category: "#villeroy&boch",
    title: "Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!",
    slug: "deri-me-28",
    content: "",
    date: "29-07-2024",
    image: BlogImg4,
  },
  {
    id: 9,
    category: "#villeroy&boch",
    title: "Shfrytëzoni uljet ekskluzive me Villeroy&Boch: Deri në 25% zbritje",
    slug: "uljet-ekskluzive",
    content: "",
    date: "29-07-2024",
    image: BlogImg2,
  },
  {
    id: 10,
    category: "#villeroy&boch",
    title:
      'Nga Vjena në Milano, ekspozita magjike "Masters of Light" nga Swarovski',
    slug: "nga-vjena",
    content: "",
    date: "29-07-2024",
    image: BlogImg1,
  },
  {
    id: 11,
    category: "#villeroy&boch",
    title:
      "Asnjë dhuratë mashkullit të familjes për Ditën e Baballarëve? Një guidë me dhurata në ByBest Shop",
    slug: "asnje-dhurate",
    content: "",
    date: "29-07-2024",
    image: BlogImg4,
  },
  {
    id: 12,
    category: "#gifts",
    title: "Pse syzet SWATCH janë një domosdoshmëri këtë verë?",
    slug: "pse-syzet",
    content: "",
    date: "29-07-2024",
    image: BlogImg5,
  },
  {
    id: 13,
    category: "#swarovski",
    title: "Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!",
    slug: "deri-uljet-net-swarivski",
    content: "",
    date: "29-07-2024",
    image: BlogImg6,
  },
  {
    id: 14,
    category: "#bybestshop",
    title: "Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!",
    slug: "orrik-net-swarivski",
    content: "",
    date: "29-07-2024",
    image: BlogImg3,
  },
  {
    id: 15,
    category: "#swatch",
    title:
      "Gjithçka që të nevojitet, në një platformë: Me ByBest Shop kursen kohë dhe para",
    slug: "gjithcka-net-swarivski",
    content: "",
    date: "29-07-2024",
    image: BlogImg2,
  },
];

export const products = [
  {
    id: 1,
    title: "LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B",
    category: "LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B",
    img1: "https://admin.bybest.shop/storage/products/645bc0cfd6548_12430756.jpg",
    img2: "https://admin.bybest.shop/storage/products/645bc0ceba512_12430763.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
    new: true,
  },
  {
    id: 2,
    title: "LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B",
    category: "LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B",
    img1: "https://admin.bybest.shop/storage/products/64303b956a842_12385477.jpg",
    img2: "https://admin.bybest.shop/storage/products/64303b91cfd69_12385481.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  },
  {
    id: 3,
    title: "Level TEAL",
    category: "SPARKLING DAISY",
    img1: "https://admin.bybest.shop/storage/products/6411c429967a4_12409318.jpg",
    img2: "https://admin.bybest.shop/storage/products/6411c42727246_12409324.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    new: true,
    sale_price: 1440,
  },
  {
    id: 4,
    title: "LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B",
    category: "LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B",
    img1: "https://admin.bybest.shop/storage/products/6411c0ad25d50_12409334.jpg",
    img2: "https://admin.bybest.shop/storage/products/6411c0ab59783_12409347.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    new: true,
    sale_price: 1440,
  },
  {
    id: 5,
    title: "Level TEAL",
    category: "SPARKLING DAISY",
    img1: "https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg",
    img2: "https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  },
  {
    id: 6,
    title: "Level TEAL",
    category: "SPARKLING DAISY",
    img1: "https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg",
    img2: "https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  },
  {
    id: 1,
    title: "Level TEAL",
    category: "SPARKLING DAISY",
    img1: "https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg",
    img2: "https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  },
  {
    id: 2,
    title: "Level TEAL",
    category: "SPARKLING DAISY",
    img1: "https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg",
    img2: "https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  },
  {
    id: 3,
    img1: "https://admin.bybest.shop/storage/products/6411c429967a4_12409318.jpg",
    img2: "https://admin.bybest.shop/storage/products/6411c42727246_12409324.jpg",
    no: "KLE20QB101",
    price: 3599,
    new: true,
    discount: "60%",
    sale_price: 1440,
  },
  {
    id: 4,
    title: "Level TEAL",
    category: "SPARKLING DAISY",
    img1: "https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg",
    img2: "https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  },
  {
    id: 5,
    title: "Level TEAL",
    category: "SPARKLING DAISY",
    img1: "https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg",
    img2: "https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  },
  {
    id: 6,
    title: "Level TEAL",
    category: "SPARKLING DAISY",
    img1: "https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg",
    img2: "https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  },
];

export const brandList = [
  {
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1654870085.png",
    numOfProducts: "588",
    name: "Swarovski",
    url: "swarovski",
  },
  {
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1644772840.png",
    numOfProducts: "411",
    name: "Swatch",
    url: "swatch",
  },

  {
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1644786966.png",
    numOfProducts: "41",
    name: "Flik flak",
    url: "flik-flak",
  },
  {
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1650011150.png",
    numOfProducts: "411",
    name: "Villeroy & Boch",
    url: "villeroy-boch",
  },
  {
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1660134654.png",
    numOfProducts: "17",
    name: "Like by Villeroy & Boch",
    url: "like-by-villeroy-boch",
  },
  {
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1644915360.png",
    numOfProducts: "50",
    name: "Sander",
    url: "sander",
  },

  {
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1644915299.png",
    numOfProducts: "29",
    name: "Mopita",
    url: "mopita",
  },
  {
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1657033286.png",
    numOfProducts: "1189",
    name: "Blukids",
    url: "blukids",
  },
  {
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1644909242.png",
    numOfProducts: "65",
    name: "Iana",
    url: "iana",
  },
];

export const storeData = [
  {
    name: "SWAROVSKI METROPOL",
    address: "Metropol - Qendra Tregtare, Rr. “Sami Frashëri”",
    city: "Tiranë, Shqipëri",
    phone: "+355 69 356 1111",
    workingHours: "09:00-21:00",
  },
  {
    name: "SWAROVSKI TEG",
    address: "Metropol - Qendra Tregtare, Rr. “Sami Frashëri”",
    city: "Tiranë, Shqipëri",
    phone: "+355 69 356 1111",
    workingHours: "09:00-21:00",
  },
  {
    name: "SWATCH METROPOL",
    address: "Metropol - Qendra Tregtare, Rr. “Sami Frashëri”",
    city: "Tiranë, Shqipëri",
    phone: "+355 69 356 1111",
    workingHours: "09:00-21:00",
  },
  {
    name: "BLUKIDS METROPOL",
    address: "Metropol - Qendra Tregtare, Rr. “Sami Frashëri”",
    city: "Tiranë, Shqipëri",
    phone: "+355 69 356 1111",
    workingHours: "09:00-21:00",
  },
  {
    name: "VILLEROY & BOCH METROPOL",
    address: "Metropol - Qendra Tregtare, Rr. “Sami Frashëri”",
    city: "Tiranë, Shqipëri",
    phone: "+355 69 356 1111",
    workingHours: "09:00-21:00",
  },
];
