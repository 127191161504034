import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DropdownFilter from "../../../components/Dropdown/dropdown-filter";
import BybestCustomerLayout, {
  customerText,
} from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import GenericTable from "../../../components/Table/generic-table";
import CustomerHeader from "../customer-header";
import { data, orderColumns } from "./columns";
import { filterOptions } from "../data";

const CustomerOrders = () => {
  const [selectedFilter, setSelectedFilter] = useState("");

  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    total: data.length,
  });

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const goToOrderDetails = (row) => {
    if (row) {
      navigate(`/customer/orders/${row.id}`, {
        state: row,
      });
    }
  };

  return (
    <BybestCustomerLayout selected="orders" key="orders">
      <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg mt-6">
        <div className="relative flex justify-between items-center w-full p-6">
          <div className="col-span-9">
            <CustomerHeader
              description={customerText("orderKeepTrack")}
              numberOfItems={data.length}
              items={customerText("ordersSm")}
              title={customerText("orders")}
            />
          </div>
          <div className="absolute right-2 md:right-0 top-12 md:top-0 md:relative">
            <DropdownFilter
              options={filterOptions}
              onChange={setSelectedFilter}
              value={selectedFilter}
            />
          </div>
        </div>
        <GenericTable
          columns={orderColumns(goToOrderDetails)}
          data={data.slice(
            (pagination.current - 1) * 10,
            pagination.current * 10
          )}
          className="w-full md:block pr-4"
          pagination={{
            ...pagination,
            onChange: handlePageChange,
          }}
          onDoubleClick={goToOrderDetails}
        />
      </div>
    </BybestCustomerLayout>
  );
};

export default CustomerOrders;
