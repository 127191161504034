import React from "react";
import { getCartTranslation } from "./cart-products";
import { CloseOutlined } from "@mui/icons-material";
import "./cart.css";

const CartListItem = ({ cartList = [], onRemoveProduct }) => {
  return cartList.map((product, index) => {
    return (
      <>
        <div className="details w-full flex" key={index}>
          <div class="p-2 rounded flex justify-center items-center">
            <img alt="" src={product?.img1} />
          </div>
          <div className="ml-2 flex flex-1 flex-col justify-between items-start">
            <div className="w-full flex flex-1 justify-between items-start">
              <div className="max-w-[200px] md:max-w-full">
                <h3 className="truncate text-[16px] text-[#000] leading-[24px] font-bold uppercase">
                  {product?.title}
                </h3>
                <p className="truncate text-[13px] text-[#000] leading-[19px] font-semibold uppercase">
                  {product?.category}
                </p>
              </div>
              <button
                className="p-1 w-[32px] h-[32px]"
                onClick={() => onRemoveProduct(product)}
              >
                <CloseOutlined />
              </button>
            </div>
            <div className="w-full flex flex-col md:flex-row flex-1 justify-end md:justify-between items-start md:items-end">
              <div className="product-select flex flex-col items-start justify-end gap-1">
                <span className="text-[13px] text-[#000] leading-[19px] uppercase">
                  {getCartTranslation("price")}:{" "}
                  <b>LEK {product?.sale_price}</b>
                </span>

                <div className="options quantity">
                  <span>{getCartTranslation("amount")}:</span>
                  <select>
                    <option value="1" selected="">
                      1
                    </option>
                    <option value="2">2</option>
                  </select>
                </div>
              </div>
              <p className="mt-1 md:mt-0 text-[13px] text-[#000] leading-[19px] uppercase">
                {getCartTranslation("total")}: <b>LEK {product?.sale_price}</b>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  });
};

export default CartListItem;
