import React from "react";

const ChoosePayment = ({
  icon,
  borderColor = "",
  title = "",
  description = "",
  isSelected = false,
  onSelect = () => {},
}) => {
  return (
    <div
      onClick={() => onSelect(title)}
      className={`border cursor-pointer rounded-[12px] w-full flex items-center justify-between gap-4 py-2 px-4 ${borderColor}`}
    >
      <div className="flex items-start gap-2">
        <span
          className={`w-3 h-3 rounded-full mt-1 ${
            isSelected ? "bg-[#E14848]" : "bg-[#677489]"
          }`}
        />
        <div className="flex flex-col gap-2">
          <h1 className="text-[#101828] text-18 font-semibold leading-7">
            {title}
          </h1>
          <span>{description}</span>
        </div>
      </div>
      <div>{icon}</div>
    </div>
  );
};

export default ChoosePayment;
