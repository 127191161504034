import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

export const SidebarLink = ({
  href,
  icon,
  label,
  selected,
  onItemClick,
  appKey,
  setOpen,
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();

      if (onItemClick) {
        onItemClick(e);
      } else if (href === "/") {
        navigate("/");
      } else {
        navigate(`/customer${href}`);
        setOpen?.(false);
      }
    },
    [appKey, href]
  );

  return (
    <div
      className={`sidebar-item cursor-pointer ${
        selected ? "sidebar-item-active" : ""
      }`}
      onClick={handleClick}
    >
      <div className="flex items-center gap-2">
        <span>{icon}</span>
        <p className="text-[#191D23] text-[13px] font-semibold">{label}</p>
      </div>
    </div>
  );
};
