import React, { useEffect, useState } from "react";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import RegisterMember from "./landing/register-member";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import { getTranslation } from "./landing/landing";
import { getBrandTranslation } from "./brands/brands";

const ByBestClub = () => {
  const { pageSEO } = useSeoData();
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = (payload, app_key) => {
    const { first_name = "", email = "", last_name = "" } = payload;

    if (first_name.length === 0 || !first_name) {
      NotificationManager.error(
        `${getTranslation("nameLabel")} ${getTranslation("isRequired")}!`
      );
    } else if (last_name.length === 0 || !last_name) {
      NotificationManager.error(
        `${getTranslation("surnameLabel")} ${getTranslation("isRequired")}!`
      );
    } else if (email.length === 0 || !email) {
      NotificationManager.error(
        `${getTranslation("emailLabel")} ${getTranslation("isRequired")}!`
      );
    } else {
      setLoading(true);
      WhiteLabelService.registerMembers(payload, app_key)
        .then(() => {
          NotificationManager.success(
            "Registration completed successfully!",
            "Success",
            5000
          );
          setResetForm(true);
          setLoading(false);
        })
        .catch(() => {
          NotificationManager.error("Oops! Something went wrong!");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (resetForm) {
      setTimeout(() => {
        setResetForm(false);
      }, 100);
    }
  }, [resetForm]);

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase ">
            {getBrandTranslation("breadcrumb")} {getBrandTranslation("myClub")}
          </p>
        </div>
        <div className="max-w-screen-main">
          <RegisterMember
            swapSections
            onSubmit={onSubmit}
            isMyClub
            resetForm={resetForm}
            isLoading={loading}
          />
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestClub;
