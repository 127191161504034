import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SaleSection } from "../../containers/header/sales-section";

const BybestDropdownMenu = ({ menu }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { language } = useSelector((state) => state.app);

  useEffect(() => {
    setIsCollapsed(false);
  }, [pathname]);

  const getTranslation = useCallback(
    (text) => {
      if (text == null) {
        return "";
      }
      return language == "en" ? text["en"] : text["sq"];
    },
    [language]
  );

  const groupfyMenus = useCallback((menu_children) => {
    let cats = menu_children.filter((item) => item.type_id == 1);
    let brands = menu_children.filter((item) => item.type_id == 2);
    let colls = menu_children.filter((item) => item.type_id == 3);
    return [
      {
        id: 1,
        type: "category",
        category: { en: "Shop by Category", sq: "Blej sipas Kategorisë" },
        items: cats.sort((a, b) => a.order - b.order),
      },
      {
        id: 2,
        type: "brand",
        category: { en: "Shop by Brand", sq: "Blej sipas Brandit" },
        items: brands.sort((a, b) => a.order - b.order),
      },
      {
        id: 3,
        type: "collection",
        category: { en: "Shop by Collection", sq: "Blej sipas koleksionit" },
        items: colls.sort((a, b) => a.order - b.order),
      },
    ].filter((d) => d.items.length > 0);
  }, []);

  const goCategory = (category, item) => {
    if (menu.label === "Blog") {
      navigate(`/blog/category/${item}`);
    } else {
      navigate(`/category/${item.replace(/\s+/g, "-").toLowerCase()}`, {
        state: {
          category,
          item,
        },
      });
    }
  };

  const exploreOffers = () => {
    if (menu?.bybest_id === 21) {
      navigate(`/${menu.link}`, {
        state: {
          item: getTranslation(menu.title),
          category: getTranslation({
            en: "Shop by Category",
            sq: "Blej sipas Kategorisë",
          }),
        },
      });
    }
  };

  return (
    <div className="relative" onClick={exploreOffers}>
      <div className={`flex items-center ${isCollapsed ? "bg-[#f4f4f4]" : ""}`}>
        <span
          onMouseEnter={() => setIsCollapsed(true)}
          onMouseLeave={() => setIsCollapsed(false)}
          className={`flex uppercase text-[13px] leading-[20px] !font-bold text-black whitespace-nowrap cursor-pointer py-[13px] px-[19px] ${
            menu.focused || menu.type_id == 1
              ? "!text-[#CB0000]"
              : "hover:!text-[#ffc107]"
          }`}
        >
          {getTranslation(menu.title)}
          {menu.type_id == 1 && (
            <img
              src="https://admin.bybest.shop/storage/upploads/by_best_image_0_1710373600.png"
              className="w-[22px] mt-[-4px] ml-[3px]"
            />
          )}
        </span>
      </div>
      {isCollapsed && menu.menu_children.length > 0 && (
        <>
          {menu.photo == null && (
            <div
              className="fixed top-[167px] overflow-hidden transition-all duration-100 delay-100 w-[150px]"
              onMouseLeave={() => setIsCollapsed(false)}
              onMouseEnter={() => setIsCollapsed(true)}
            >
              <div className="grid grid-cols-2">
                <ul className="flex flex-col justify-between gap-5 items-start text-18 bg-[#f4f4f4] px-5 py-2 w-fit">
                  {menu.menu_children.map((item, i) => (
                    <Link
                      key={i}
                      to={item.link}
                      state={{
                        category: getTranslation(menu.title),
                        item: getTranslation(item.text),
                      }}
                      className="py-1 w-full text-[12px] text-[#666] hover:!text-[#ffc107] font-bold opacity-60 hover:font-bold whitespace-nowrap uppercase cursor-pointer"
                    >
                      {getTranslation(item.text)}
                    </Link>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {menu.photo != null && (
            <div className="w-[100vw] fixed top-[167px] left-0 right-0 flex justify-center items-center overflow-hidden transition-all duration-100 delay-100">
              <div
                className={`w-full max-w-screen-main bg-[#f4f4f4] p-5 grid grid-cols-6 gap-8 ${
                  menu.id === 10 ? "grid grid-cols-9 gap-2" : ""
                }`}
                onMouseLeave={() => setIsCollapsed(false)}
                onMouseEnter={() => setIsCollapsed(true)}
              >
                <div>
                  <img
                    src={menu.photo}
                    className="w-full h-full object-contain"
                    alt="icon"
                  />
                </div>
                {groupfyMenus(menu.menu_children).map((item, i) => (
                  <div className="flex flex-col items-start" key={i}>
                    <p className="text-[12px] font-bold text-[#000] uppercase mb-[10px]">
                      {getTranslation(item.category)}
                    </p>
                    {item?.items?.map((_item, i) => {
                      return (
                        <Link
                          key={i}
                          className="flex justify-start items-center cursor-pointer py-1 text-[12px] text-[#666] hover:!text-[#ffc107] font-medium hover:font-bold uppercase"
                          to={`/${item.type}/${_item.link}?search=${menu.group_id}`}
                          state={{
                            category: getTranslation(item.category),
                            item: getTranslation(_item.text),
                          }}
                        >
                          {getTranslation(_item.text)}
                          {((_item.text?.en || "").toLowerCase() == "swatch" ||
                            (_item.text?.en || "").toLowerCase() ==
                              "flik flak") && (
                            <img
                              alt=""
                              src="https://bybest.shop/assets/img/icons/swiss.png"
                              className="ml-1 h-[13px] object-contain"
                            />
                          )}
                        </Link>
                      );
                    })}
                    <Link
                      to={`/group/${menu.group_id}`}
                      className="mt-[2px] text-[12px] cursor-pointer font-bold text-[#666] uppercase hover:text-[#ffc107]"
                    >
                      {t("bybest.header.see_all")}
                    </Link>
                  </div>
                ))}
                {menu.id === 10 && <SaleSection />}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BybestDropdownMenu;
