import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import ImgBrands from "../../assets/images/bybest/bb-footer-brands.png";
import Svg_phone from "../../assets/svgs/bybest/bb-phone.svg";
import GoogleReview from "../../components/google-review";
import { footerSections, hasPage, socials } from "./data";
import "./index.css";
import NewsletterSubscription from "./newsletter-subscription";

export const getFooterTranslation = (label = "") => t(`bybest.footer.${label}`);

const BBFooter = () => {
  const navigate = useNavigate();
  const vbToken = localStorage.getItem("vbToken");

  const isLoggedInuser = window.location.pathname.includes("customer/");

  const goTo = (url = "") => {
    if (hasPage.includes(url)) {
      navigate(`/page/${url}`);
    } else {
      navigate(`/${url}`);
    }
  };

  return (
    <div className="w-full flex justify-center items-center border-t border-t-[#D2D2D2] mt-10 sm:px-6 md:px-8 lg:px-[10%]">
      <div className="w-full max-w-screen-main pt-10 px-5 xl:px-0">
        {(!vbToken || !isLoggedInuser) && (
          <div className="grid grid-cols-1 md:grid-cols-12 gap-0 md:gap-4 md:px-0">
            <div className="md:col-span-6 md:pr-6 flex flex-col items-center md:items-stretch w-full p-0">
              <NewsletterSubscription />

              <div className="call-us-bb justify-center md:justify-start w-full">
                <div className="flex justify-center items-center">
                  <div className="mr-3 text-[12px] leading-[16px] text-[#FF9800] font-normal">
                    {getFooterTranslation("call")}
                  </div>
                  <img alt="Phone Icon" src={Svg_phone} />
                  <div className="ml-3 text-[12px] leading-[16px] text-[#FF9800] font-normal">
                    068 80 52 000
                  </div>
                </div>
              </div>

              <ul className="flex flex-row gap-6 md:gap-10 mt-4">
                {socials.map(({ href, src, label }, index) => (
                  <a
                    key={index}
                    rel="noreferrer"
                    href={href}
                    aria-label={`VenueBoost ${label} Account`}
                    target="_blank"
                  >
                    <img alt={`${label} Icon`} src={src} />
                  </a>
                ))}
              </ul>
            </div>

            <div className="md:col-span-6 flex flex-col items-start gap-8">
              <div className="grid justify-center md:flex md:justify-between gap-0 md:gap-4 w-full">
                {footerSections.map(({ title, links, children }, idx) => (
                  <div
                    key={idx}
                    className={`md:col-span-2 flex flex-col items-center md:items-baseline ${
                      idx > 0 ? "md:border-l md:border-l-[#D2D2D2] md:pl-4" : ""
                    }`}
                  >
                    <h3 className="h3-title mt-10 md:mt-0">{title}</h3>
                    {links.map((link, linkIdx) => (
                      <div
                        key={linkIdx}
                        onClick={() => goTo(link.href)}
                        className="footer-list-item mt-3 cursor-pointer"
                      >
                        {link.name}
                      </div>
                    ))}
                    {children &&
                      children.map((child, childIdx) => (
                        <React.Fragment key={childIdx}>
                          <h3 className="h3-title mt-10 md:mt-10">
                            {child.title}
                          </h3>
                          {child.links.map((childLink, childLinkIdx) => (
                            <div
                              onClick={() => goTo(childLink.href)}
                              key={childLinkIdx}
                              className="footer-list-item mt-3 cursor-pointer"
                            >
                              {childLink.name}
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                  </div>
                ))}
              </div>

              <div className="flex justify-end w-full">
                <a
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/ANUSADUfq3B9Dp267?g_st=com.google.maps.preview.copy"
                  target="_blank"
                  className="flex lg:justify-end justify-center w-full"
                >
                  <GoogleReview />
                </a>
              </div>
            </div>
          </div>
        )}
        <div className="hidden md:flex w-full mt-8 mb-5 px-3 justify-center items-center">
          <img alt="Brands" src={ImgBrands} />
        </div>
        <div className="w-full border-t border-t-[#D2D2D2] p-4 flex justify-center items-center">
          <p className="text-[11px] text-[#000] leading-[18px]">
            © 1996-2024 By Best Duty Free Sh.p.k - NIPT: K01621002N
          </p>
        </div>
      </div>
    </div>
  );
};

export default BBFooter;
