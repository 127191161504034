export const byBestRetailRouteNames = {
  order_tracking: "/:appKey/order/tracking",
  my_club: "/:appKey/my-club",
  cart: "/:appKey/cart",
  checkout: "/:appKey/checkout",
  payment: "/:appKey/payment",
  success_checkout: "/:appKey/success-checkout",
  app_home: "/:appKey",
  product_details: "/:appKey/products/:product_id/:product_slug",
  brand: "/:appKey/brand/:brand_url",
  brand_search: "/:appKey/brand/:brand_url",
  category: "/:appKey/category/:category_url",
  category_search: "/:appKey/category/:category_url",
  collection: "/:appKey/collection/:collection_url",
  collection_search: "/:appKey/collection/:collection_url",
  group: "/:appKey/group/:group_id",
  group_page: "/:appKey/group/:group_id?page=x",
  wishlist: "/:appKey/wishlist",
  blog_category: "/:appKey/blog/category/:category_name",
  search: "/:appKey/search",
  virtual_gift_card: "/:appKey/virtual-gift-card",
  page: "/:appKey/page/:page_id",
  page_return_policy: "/page/return-policy",
  page_help: "/page/help",
  page_faq: "/page/frequently-asked-questions",
  page_job_listing: "/page/job-listing",
  page_contact_us: "/page/contact-us",
  page_about_us: "/page/about-us",
  page_stores_and_hours: "/page/stores-and-hours",
  page_privacy_policy: "/page/privacy-policy",
  page_cookies: "/page/cookies",
  page_prices_and_currency: "/page/prices-and-currency",
  page_payment_methods: "/page/payment-methods",
  page_shipping_methods: "/page/shipping-methods",
  page_product_exchange: "/page/product-exchange",
};

const RouteNames = {
  ...byBestRetailRouteNames,
  not_found: "/not-found",

  // Customer
  dashboard: "/dashboard",
  reservations: "/reservations",
  payments: "/:app_key/customer/payments",
  login: "/login",
  register: "/register",
  email_not_verified: "/email-not-verified",
  verify_email: "/verify-email",
};

export default RouteNames;
