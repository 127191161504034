import React, { useState } from "react";
import "./index.css";
import { FavoriteBorderOutlined } from "@mui/icons-material";
import { MdFavorite } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ProductPrice from "./Price";

const BbProduct = ({
  app_key,
  product,
  onFavoriteChange = () => { },
  className = "",
}) => {
  const { t } = useTranslation();
  const [visibleSecond, setVisibleSecond] = useState(false);
  return (
    <div
      className={`cursor-pointer flex w-[220px] min-w-[220px] max-w-[220px] md:w-full md:min-w-full md:max-w-full flex-col rounded-[5px] bb-product relative ${className}`}
    >
      <Link to={`/shop/${app_key}/products/${product.id}/${product.product_url}`} >
        <div
          className="w-full h-[180px] md:h-[300px] rounded-[5px] relative image"
          onMouseEnter={() => setVisibleSecond(true)}
          onMouseLeave={() => setVisibleSecond(false)}
        >
          <img
            alt=""
            className="absolute border top-0 z-[1] bg-white left-0 w-full h-[180px] md:h-[300px] rounded-[5px] object-cover"
            src={product.image_path}
          />
          {
            product.galley?.length > 0 &&
            <img
              alt=""
              className={
                "absolute border top-0 left-0 w-full h-[180px] md:h-[300px] rounded-[5px] object-cover " +
                (visibleSecond ? " z-[2]" : "")
              }
              src={product.galley[0].photo_name}
            />
          }
          <div class="extras z-[2]">
            {
              product.stock_quantity == 0 ?
                <div class="label" style="color: red;">{t(`bybest.products.out_of_stock`)} </div>
                :
                product.is_best_seller ?
                  <img
                    style={{
                      width: 40,
                      display: "block",
                      background: "none",
                    }}
                    src="https://admin.bybest.shop/storage/upploads/by_best_image_0_1701910517.png"
                    alt=""
                  />
                  :
                  (moment().diff(moment(product.created_at), 'days') <= 31) &&
                  <div class="label">{t(`bybest.products.new_product`)}</div>
            }
          </div>
        </div>
      </Link>
      <div class="data">
        <Link to={`/shop/${app_key}/products/${product.id}/${product.product_url}`} >
          <div class="title">{product.title}</div>
        </Link>
        <div class="sub-title" dangerouslySetInnerHTML={{__html: product.short_description}}> 
        </div>
        <ProductPrice product={product}/>
      </div>
      <div className="absolute top-4 right-4 z-[3]" onClick={() => onFavoriteChange(product)}>
        {product.favorite ? (
          <MdFavorite style={{ color: "black", fontSize: 24, fill: "black" }} />
        ) : (
          <FavoriteBorderOutlined style={{ color: "#000", fontSize: 24 }} />
        )}
      </div>
    </div>
  );
};

export default BbProduct;
