import React from "react";
import ByBestSidebar from "../sideBar";
import BBMainLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../../services/hooks/use-seo";
import { getBrandTranslation } from "../brands/brands";
import { getAboutTranslation } from "../about";
import { t } from "i18next";
import "./cookies.css";

export const getCookiesTranslation = (label = "") =>
  t(`bybest.cookies.${label}`);

const ByBestCookies = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
            {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
            {">"} {getCookiesTranslation("cookies")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <h3 className="text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getCookiesTranslation("cookiePolicy")}
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                {getCookiesTranslation("cookiePolicyDescription")}{" "}
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getCookiesTranslation("aboutCookies")}
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                {getCookiesTranslation("aboutCookiesDescription")}{" "}
              </p>
              <ul className=" list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  {getCookiesTranslation("rememberPreferences")}
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  {getCookiesTranslation("measureUsage")}
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  {getCookiesTranslation("cookieExplanation")}
                </li>
              </ul>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#3c4043] uppercase">
                {getCookiesTranslation("cookieTypes")}{" "}
              </h3>
              <p className="text-[13px] leading-[25px] text-[#3c4043]  ">
                {getCookiesTranslation("cookieTypesDescription")}{" "}
              </p>
              <ul className="mt-2 list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#3c4043] ">
                  {getCookiesTranslation("sessionCookies")}
                  <br />
                  {getCookiesTranslation("sessionCookiesDescription")}
                </li>
                <li className="text-[12px] leading-[20px] text-[#3c4043] ">
                  {getCookiesTranslation("trackingCookies")}
                  <br />
                  {getCookiesTranslation("trackingCookiesDescription")}
                </li>
                <li className="text-[12px] leading-[20px] text-[#3c4043] ">
                  {getCookiesTranslation("persistentCookies")}
                  <br />
                  {getCookiesTranslation("persistentCookiesDescriptions")}
                </li>
                <li className="text-[12px] leading-[20px] text-[#3c4043] ">
                  {getCookiesTranslation("howWeUseCookies")}
                  <br />
                  {getCookiesTranslation("howWeUseCookiesDescriptions")}
                </li>
              </ul>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getCookiesTranslation("performanceCookies")}
              </h3>
              {getCookiesTranslation("performanceCookiesDescription")}

              <p className="text-[13px] leading-[25px] text-[#000]  ">
                {getCookiesTranslation("dotknowledge")}{" "}
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getCookiesTranslation("measuringWebsiteUsage")}
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                {getCookiesTranslation("measuringWebsiteUsage1")}
                <br />
                {getCookiesTranslation("measuringWebsiteUsage2")}
                <br />
                {getCookiesTranslation("measuringWebsiteUsage3")}

                <br />
                <div className="overflow-x-auto table-cookies-wrapper">
                  <table className="table table-bordered w-full text-sm md:text-base border border-[#dee2e6] mt-4">
                    <tbody>
                      <tr>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("cookieName")}
                        </td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("expirationTime")}
                        </td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("description")}
                        </td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("purpose")}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="p-2 border border-[#dee2e6]"
                          style={{ wordBreak: "break-all" }}
                        >
                          {getCookiesTranslation("xsrfToken")}
                        </td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("hours")}
                        </td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("xsrfTokenDescription")}
                        </td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("neccesary")}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="p-2 border border-[#dee2e6]"
                          style={{ wordBreak: "break-all" }}
                        >
                          {getCookiesTranslation("cookieNoticeAccepted")}
                        </td>
                        <td className="p-2 border border-[#dee2e6]">1 year</td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("cookieUsed")}
                        </td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("neccesary")}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="p-2 border border-[#dee2e6]"
                          style={{ wordBreak: "break-all" }}
                        >
                          by_best_eccommerce_session
                        </td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("week")}
                        </td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("cookieNeed")}
                        </td>
                        <td className="p-2 border border-[#dee2e6]">
                          {getCookiesTranslation("neccesary")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getCookiesTranslation("currentConcerns")}
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                {getCookiesTranslation("currentConcernsDescription1")}
                <br />
                {getCookiesTranslation("currentConcernsDescription2")}
                <br />
                {getCookiesTranslation("currentConcernsDescription3")}
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getCookiesTranslation("manageCookies")}
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]">
                {getCookiesTranslation("manageCookiesDescription1")}
                <br />
                {getCookiesTranslation("manageCookiesDescription2")}
                <br />
                <br />
                {getCookiesTranslation("manageCookiesDescription3")}
                <br />
                <a
                  rel="noreferrer"
                  className="underline"
                  href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  target="_blank"
                >
                  <span>{getCookiesTranslation("cookieSettingsIE")}&nbsp;</span>
                </a>
                <br />
                <a
                  rel="noreferrer"
                  className="underline"
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                  target="_blank"
                >
                  <span>
                    {getCookiesTranslation("cookieSettingsFirefox")}&nbsp;
                  </span>
                </a>
                <br />
                <a
                  rel="noreferrer"
                  className="underline"
                  href="https://support.google.com/chrome/answer/95647?hl=en"
                  target="_blank"
                >
                  <span>
                    {getCookiesTranslation("cookieSettingsChrome")}&nbsp;
                  </span>
                </a>
                <br />
                <a
                  rel="noreferrer"
                  className="underline"
                  href="https://support.apple.com/?path=Safari/3.0/en/9277.html"
                  target="_blank"
                >
                  <span>
                    {getCookiesTranslation("cookieSettingsSafari")}&nbsp;
                  </span>
                </a>
                <br />
                <br />
                {getCookiesTranslation("thirdParty")}{" "}
                <a
                  rel="noreferrer"
                  className=" underline"
                  href="https://www.youronlinechoices.com/uk/"
                  target={"_blank"}
                >
                  {getCookiesTranslation("onlineChoice")}
                </a>{" "}
                {getCookiesTranslation("allowsYou")}
                <br />{" "}
                <a
                  rel="noreferrer"
                  className="underline"
                  href="https://thenai.org/"
                  target={"_blank"}
                >
                  {getCookiesTranslation("networkInitiave")}
                </a>
                {getCookiesTranslation("alsoProvides")}{" "}
                <a
                  rel="noreferrer"
                  className=" underline"
                  href="https://optout.networkadvertising.org/?c=1#!/"
                  target={"_blank"}
                >
                  opt-out controls
                </a>{" "}
                {getCookiesTranslation("targetAd")}
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getCookiesTranslation("furtherInfo")}
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000] uppercase">
                {getCookiesTranslation("moreInformation")}{" "}
                <a
                  rel="noreferrer"
                  className="underline"
                  href="https://www.aboutcookies.org/"
                  target={"_blank"}
                >
                  {getCookiesTranslation("aboutCookies")}
                </a>
                <br />
                {getCookiesTranslation("updatedLaw")}{" "}
                <a
                  rel="noreferrer"
                  className="underline"
                  href="http://www.idp.al/"
                  target={"_blank"}
                >
                  www.idp.al.{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestCookies;
