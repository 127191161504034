import React from "react";
import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import NotFound from "../../views/notFound/index.js";
import BBGuestLayout from "../layouts/BBGuestLayout";
import bybestGuestRoutes from "./bybest-guest-routes.jsx";
import bybestRoutes from "./bybest-routes.jsx";

const AppRoutes = () => {
  const { pathname } = useLocation();
  const allRoutes = [...bybestGuestRoutes, ...bybestRoutes];

  const isRouteMatched = allRoutes.some((route) => {
    return route?.path && matchPath({ path: route.path, end: true }, pathname);
  });

  return (
    <Routes>
      {allRoutes.map((route, index) =>
        route && route.path && route.element ? (
          <Route key={index} path={route.path} element={<BBGuestLayout />}>
            <Route index element={route.element} />
          </Route>
        ) : null
      )}
      {!isRouteMatched && <Route path="*" element={<NotFound />} />}
    </Routes>
  );
};

export default AppRoutes;
