import React from "react";
import { useNavigate } from "react-router-dom";
import menuIcon from "../../assets/svgs/bybest/menu.svg";
import searchBlackIcon from "../../assets/svgs/bybest/search.svg";

const MobileHeader = ({ open, setOpen, headerIcons = [] }) => {
  const navigate = useNavigate();

  return (
    <div className="flex md:hidden z-10 top-[32px] fixed w-full justify-between items-center px-5 py-3 gap-5 border-b border-b-[#F4F4F4] bg-white">
      <div className="flex items-center gap-4">
        <div
          className="flex justify-center items-center"
          onClick={() => setOpen(!open)}
        >
          <img alt="" src={menuIcon} />
        </div>
        <div
          className="flex justify-center items-center"
          onClick={() => setOpen(!open)}
        >
          <img alt="" src={searchBlackIcon} />
        </div>
      </div>

      <div
        className="flex-1 flex justify-center items-center"
        onClick={() => navigate("/")}
      >
        <img
          alt="bybestlogo"
          src="https://bybest.shop/assets/img/bybest-logo.png"
          className={"w-[162px] h-[40px] object-contain"}
        />
      </div>

      <div className="flex items-center gap-2 justify-end">
        {headerIcons.map((icon, index) => {
          return (
            <div
              key={index}
              className={`flex justify-center items-center relative ${
                icon.desktopOnly ? "hidden md:block" : ""
              }`}
              onClick={icon.onClick}
            >
              {icon.badgeCount > 0 && (
                <span className="icon-absolute !text-white">
                  {icon.badgeCount}
                </span>
              )}
              <span
                style={
                  index === 3
                    ? {
                        borderRight: "1px #dee2e6 solid",
                        paddingRight: 8,
                      }
                    : undefined
                }
              >
                {icon.icon || <img alt="" src={icon.src} />}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileHeader;
