import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import BybestCartStepper from "../../components/BybestCartStepper";
import ImgGed from "../../assets/images/bybest/ged.png";
import SvgRadioChecked from "../../assets/svgs/bybest/radio-checked.svg";
import SvgRadio from "../../assets/svgs/bybest/radio.svg";
import SvgCash from "../../assets/svgs/bybest/cash.svg";
import SvgCard from "../../assets/svgs/bybest/card.svg";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import PaymentFormModal from "./components/payment-form";
import { getCartTranslation } from "./cart/cart-products";
import { getBrandTranslation } from "./brands/brands";
import BktPaymentForm from "./bkt-payment/BktPaymentForm";
import { NotificationManager } from "react-notifications";
import WhiteLabelService from "../../services/whiteLabelService";

export const PaymentMethod = ({ data, isSelected, onSelect }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className="w-full cursor-pointer flex justify-center items-center p-2 rounded-[5px] bg-[#f2f2f2] border border-[#333]"
        onClick={() => {
          if (data?.title === "Para ne Dore") {
            onSelect(data);
            setOpen(false);
          } else {
            onSelect(data);
          }
        }}
      >
        <img src={isSelected ? SvgRadioChecked : SvgRadio} alt="" />
        <img
          src={data?.img}
          className="w-[30px] h-[30px] object-contain mx-2"
          alt=""
        />
        <div className="flex-1">
          <h1 className="text-[14px] text-[#000] font-bold">{data?.title}</h1>
          <p className="text-[12px] text-[#000]">{data?.desc}</p>
        </div>
      </div>
      <PaymentFormModal
        showModal={open}
        onClose={(token) => {
          if (token) {
            onSelect(data, token);
          }
          setOpen(false);
        }}
      />
    </>
  );
};

const ByBestCartPaymethod = () => {
  const { pageSEO } = useSeoData();
  const { state } = useLocation();
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const errorParam = searchParams.get("error");

  const [payload, setPayload] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [showWrong, setShowWrong] = useState(errorParam ?? false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [curPaymentMethod, setCurPaymentMethod] = useState("cash");

  useEffect(() => {
    if (state) {
      setPayload(state.formData);
    }
  }, [state]);


  const product = {
    id: 706,
    sale_price: 50,
}

  const paymentMethods = [
    {
      id: "cash",
      img: SvgCash,
      title: "Para ne Dore",
      desc: "Paguaj ne dorezim",
    },
    {
      id: "card",
      img: SvgCard,
      title: "Credit/Debit Card",
      desc: "Paguaj online nepermjet...",
    },
  ];

  const onSubmit = (values) => {
    if (values) {
      WhiteLabelService.checkoutOrderBybest({
        ...values,
        payment_method: curPaymentMethod,
        order_products: [{ product_id: product.id, product_quantity: 1 }],
        app_key,
      })
        .then((res) => {
          if(curPaymentMethod === "cash"){
            navigate("/cart/success");
          }else {
            setPaymentInfo(res);
          }
        })
        .catch((error) => {
          setShowWrong(true);
          NotificationManager.error(
            error?.message ||
              "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
            "Error",
            3000
          );
        });
    }else {
      setShowWrong(true);
    }
  };

  const handleNavigateBack = ()=>{
    navigate("/checkout")
  }

  if (paymentInfo) {
    return (
      <BktPaymentForm
        paymentUrl={paymentInfo.payment_url}
        paymentData={paymentInfo.payment_data}
      />
    );
  }

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        {showMessage && (
          <div className="success-message w-full">
            <span>{getCartTranslation("cancelTransaction")}</span>
          </div>
        )}
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase ">
            {getBrandTranslation("breadcrumb")} {getCartTranslation("end")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5 px-4`}>
          <div className="flex justify-start items-center mb-5">
            <FaChevronLeft
              color="#000"
              size={20}
              onClick={handleNavigateBack}
            />
            <span className=" ml-1 text-[16px] text-[#000] font-semibold">
              {getCartTranslation("continue")}
            </span>
          </div>
          <BybestCartStepper curStep={2} />
          <h1 className="mt-5 text-[16px] text-[#000] leading-[24px] font-bold uppercase">
            {getCartTranslation("endPayment")}
          </h1>
          <p className="text-[13px] text-[#000] leading-[19px] font-semibold ">
            {getCartTranslation("payOrder")}
          </p>
          {showWrong && (
            <div className="error-message w-full mt-2">
              <span>{getCartTranslation("wentWrong")}</span>
            </div>
          )}
          <div className="mt-5 w-full grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-6">
            <div class="col-span-1 md:col-span-2 ">
              <div className=" w-full grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
                {paymentMethods.map((pm, index) => (
                  <PaymentMethod
                    key={index}
                    data={pm}
                    isSelected={pm.id === curPaymentMethod}
                    onSelect={(p) => setCurPaymentMethod(p.id)}
                  />
                ))}
              </div>
            </div>
            <div class="col-span-1">
              <div class="w-full rounded bg-[#F2F2F2] p-4 flex flex-col justify-start items-start">
                <div className="w-full flex justify-between items-start">
                  <h2 className="uppercase text-[16px] text-[#000] font-semibold leading-[24px]">
                    {getCartTranslation("subtotal")}
                  </h2>
                  <h2 className="text-[16px] text-[#000] font-semibold leading-[24px]">
                    LEK {product?.sale_price}
                  </h2>
                </div>
                <div className="mt-3 w-full flex justify-between items-center">
                  <div className="flex justify-center items-center">
                    <img src={ImgGed} className="w-[35px] h-[35px]" alt="" />
                    <div className="ml-2">
                      <h2 className="text-[14px] text-[#000] font-bold leading-[18px]">
                        Ged Normale
                      </h2>
                      <p className="text-[12px] leading-[16px] text-[#000]">
                        {getCartTranslation("time")}: ~24 Ore
                      </p>
                    </div>
                  </div>
                  <h2 className="text-[16px] text-[#000] font-semibold leading-[24px]">
                    LEK {product?.sale_price}
                  </h2>
                </div>
                <div className="mt-4 w-full flex justify-between items-start">
                  <h2 className="uppercase text-[16px] text-[#000] font-bold leading-[24px]">
                    {getCartTranslation("total")}
                  </h2>
                  <div className="flex flex-col justify-end items-end">
                    <h2 className="text-[16px] text-[#000] font-bold leading-[24px]">
                      LEK {product?.sale_price}
                    </h2>
                    <p className="mt-2 text-[11px] text-[#000] leading-[14px] uppercase">
                      {getBrandTranslation("reward")} 160{" "}
                      {getBrandTranslation("points")}
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="mt-4 rounded bg-[#000] w-full h-[45px]  font-bold text-[14px] text-white uppercase"
                onClick={() => onSubmit(payload)}
              >
                {getCartTranslation("buy")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestCartPaymethod;
