import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { useFormik } from "formik";
import "./index.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRegisterConfig } from "../../redux/actions/contactsales";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import { getTranslation } from "../bybest/landing/landing";
import WhiteLabelService from "../../services/whiteLabelService";
import { Spin } from "antd";
import { t } from "i18next";

const validator = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required("First name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  last_name: yup
    .string()
    .trim()
    .required("Last name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .max(72, "Max exceeded")
    .min(5, "Min not met")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .max(250, "Max exceeded")
    .min(6, "Min not met"),
  acceptTerm: yup
    .boolean()
    .required("You must accept the terms and conditions")
    .oneOf([true], "You must accept the terms and conditions"),
});

const ByBestRegister = () => {
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [isReferral, setIsReferral] = useState("");
  const [loading, setLoading] = useState(false);

  const formikProps = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      country: null,
      city: null,
      address: "",
      postcode: "",
      phone_number: "",
      acceptTerm: false,
    },
    validationSchema: validator,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const vbToken = localStorage.getItem("vbToken");

  const [countries, setCountries] = useState([
    { value: "", title: "Please select" },
  ]);
  const [cities, setCities] = useState([
    { value: "", title: getTranslation("pleaseSelect") },
  ]);

  useEffect(() => {
    loadRegisterConfig();
  }, []);

  useEffect(() => {
    if (vbToken) {
      navigate(`/customer/orders`);
    }
  }, [vbToken, app_key]);

  useEffect(() => {
    formikProps.setFieldValue("email", state?.email);
  }, [state]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.sessionStorage.clear();
  }, []);

  const loadRegisterConfig = () => {
    getRegisterConfig()
      .then((data) => {
        setCountries(
          data?.countries.map((country) => ({
            value: country.name,
            title: country.name,
            ...country,
          })) || []
        );

        const statesForSelectedCountry = [
          { value: "", title: "Please select" },
          ...data?.countries[0].states?.map((state) => ({
            value: state.name,
            title: state.name,
          })),
        ];
        setCities(statesForSelectedCountry);
      })
      .catch(() => {});
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleChange = (e) => {
    formikProps.setFieldValue([e.target.name], e.target.value);

    if (e.target.name === "country") {
      // Get states for the selected country
      const selectedCountry = countries.find(
        (country) => country.value === e.target.value
      );
      const statesForSelectedCountry =
        selectedCountry && selectedCountry.id
          ? [
              { value: "", title: "Please select" },
              ...selectedCountry.states?.map((state) => ({
                value: state.name,
                title: state.name,
              })),
            ]
          : [{ value: "", title: "Please select" }];

      setCities(statesForSelectedCountry);
    }
  };

  const onLogin = () => {
    navigate("/login");
  };

  const handleRegister = () => {
    setLoading(true);
    const payload = { ...formikProps.values, source: "bybest.shop_web" };
    WhiteLabelService.bybestRegister(payload, app_key)
      .then((response) => {
        NotificationManager.success(
          t("bybest.email.register"),
          "Success",
          3000
        );
        setLoading(false);
        navigate(`/registered-sucessfully`);
        localStorage.setItem("registerToken", response?.access_token);
      })
      .catch((error) => {
        setLoading(false);
        NotificationManager.error(
          error?.message || "Something went wrong!",
          "Error",
          3000
        );
      });
  };

  return (
    <BBMainLayout clientKey="bybest">
      <div className="w-full py-5 md:py-6 px-4 flex flex-col align-col-middle bg-[#DEE2E6] register-wrapper mt-4">
        <div className="p-6 w-full md:w-[440px] rounded-[4px] border bg-white border-[#00000020]">
          <h1 className="text-[16px] font-bold leading-[24px] text-[#000]">
            {getTranslation("register")}
          </h1>
          <p className="text-[12px] leading-[18px] text-[#000]">
            {getTranslation("createAccount")}
          </p>
          <div className="flex flex-col gap-2 mt-3">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-7">
              <div>
                <p className="text-[12px] leading-[18px] text-[#000]">
                  {getTranslation("nameLabel")}
                </p>
                <input
                  name="first_name"
                  placeholder={getTranslation("nameLabel")}
                  value={formikProps.values.first_name}
                  error={formikProps.errors.first_name}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                />
              </div>
              <div>
                <p className="text-[12px] leading-[18px] text-[#000]">
                  {getTranslation("surnameLabel")}
                </p>
                <input
                  name="last_name"
                  placeholder={getTranslation("surnameLabel")}
                  value={formikProps.values.last_name}
                  error={formikProps.errors.last_name}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                />
              </div>
            </div>
            <div>
              <p className="text-[12px] leading-[18px] text-[#000]">Email</p>
              <input
                name="email"
                type="email"
                placeholder="Email"
                value={formikProps.values.email}
                error={formikProps.errors.email}
                onChange={handleChange}
                className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-7">
              <div>
                <p className="text-[12px] leading-[18px] text-[#000]">
                  {getTranslation("state")}
                </p>
                <select
                  name={"country"}
                  value={formikProps.values.country}
                  error={formikProps.errors.country}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                >
                  {countries.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <p className="text-[12px] leading-[18px] text-[#000]">
                  {getTranslation("city")}
                </p>
                <select
                  name="city"
                  value={formikProps.values.city}
                  error={formikProps.errors.city}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                >
                  {cities.map((item, index) => (
                    <option
                      key={index}
                      value={item.value}
                      disabled={item.value === ""}
                    >
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-7">
              <div>
                <p className="text-[12px] leading-[18px] text-[#000]">Adresa</p>
                <input
                  name="address"
                  placeholder={getTranslation("addressLabel")}
                  value={formikProps.values.address}
                  error={formikProps.errors.address}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                />
              </div>
              <div>
                <p className="text-[12px] leading-[18px] text-[#000]">
                  {getTranslation("postalCode")}
                </p>
                <input
                  name="postcode"
                  placeholder={getTranslation("postalCode")}
                  value={formikProps.values.postcode}
                  error={formikProps.errors.postcode}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                />
              </div>
            </div>
            <div>
              <p className="text-[12px] leading-[18px] text-[#000]">
                {getTranslation("phoneLabel")}
              </p>
              <input
                name="phone_number"
                type="number"
                placeholder={getTranslation("phoneLabel")}
                value={formikProps.values.phone_number}
                error={formikProps.errors.phone_number}
                onChange={handleChange}
                className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
              />
            </div>

            <div>
              <p className="text-[12px] leading-[18px] text-[#000]">
                {getTranslation("password")}
              </p>
              <div className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400 ">
                <input
                  type={type}
                  name="password"
                  value={formikProps.values.password}
                  error={formikProps.errors.password}
                  onChange={handleChange}
                  autoComplete="current-password"
                  className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
                />
                <span
                  className="flex justify-around items-center"
                  style={{ color: "#CBD5E1" }}
                  onClick={handleToggle}
                >
                  <Icon class="absolute " icon={icon} size={18} />
                </span>
              </div>
            </div>

            <div className="flex items-center justify-between gap-1">
              <p className="text-[12px] leading-[18px] text-[#000]">
                {getTranslation("referralCode")}
              </p>

              <div className="flex items-center gap-2 mt-4">
                <input
                  name="isReferral"
                  id="isReferral"
                  type="checkbox"
                  onChange={(e) => setIsReferral(e.target.checked)}
                  className="border-[#CED4DA] border-[1px] rounded-[4px] flex focus:border-blue-400"
                />

                <label
                  htmlFor="isReferral"
                  className="text-[12px] leading-[18px] text-[#000] cursor-pointer"
                >
                  {getTranslation("haveReferralCode")}
                </label>
              </div>
            </div>
            <input
              disabled={!isReferral}
              name="referral_code"
              type="text"
              placeholder={getTranslation("referralCode")}
              value={formikProps.values.referral_code}
              error={formikProps.errors.referral_code}
              onChange={handleChange}
              className={`mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400 ${
                isReferral ? "" : "cursor-not-allowed"
              }`}
            />

            <button
              disabled={loading}
              className="mt-5  text-white text-[14px] leading-[21px] font-medium bg-[#000]  rounded-[4px] h-[41px] w-full"
              onClick={handleRegister}
            >
              {loading ? <Spin /> : getTranslation("register")}
            </button>
            <div className="mt-4 w-full flex justify-center items-center ">
              <p className="text-[#000] font-medium text-[14px] leading-[21px]">
                {getTranslation("haveAccount")}
              </p>
              <button
                className="px-2 h-[24px] text-[#000] font-medium text-[14px] leading-[21px] underline"
                onClick={onLogin}
              >
                {getTranslation("login")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestRegister;
