import Retail1 from "../../../assets/images/retail/retail1.png";
import Retail2 from "../../../assets/images/retail/retail2.png";
import Retail3 from "../../../assets/images/retail/retail3.png";
import Retail4 from "../../../assets/images/retail/retail4.png";

export const wishlistItems = [
  {
    image: Retail1,
    title: "Navy Joy",
    brand: "Swatch",
    collection: "Colors of Joy",
    diameter: "Ø 47.00",
    gender: "Unisex",
    mechanism: "Quartz",
    price: 3500,
  },
  {
    image: Retail2,
    title: "Harmonia Bracelet",
    brand: "Swarovski",
    price: 3500,
  },
  {
    image: Retail3,
    title: "Lucent Hoop Earrings",
    brand: "Swarovski",
    price: 20000,
  },
  {
    image: Retail4,
    title: "That's So Peachy",
    brand: "Swatch",
    collection: "Swatch Essentials",
    price: 22500,
  },
  {
    image: Retail1,
    title: "Navy Joy",
    brand: "Swatch",
    collection: "Colors of Joy",
    diameter: "Ø 47.00",
    gender: "Unisex",
    mechanism: "Quartz",
    price: 3500,
  },
  {
    image: Retail2,
    title: "Harmonia Bracelet",
    brand: "Swarovski",
    price: 3500,
  },
];
