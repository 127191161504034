import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import BybestCartStepper from "../../../components/BybestCartStepper";
import BBMainLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../../services/hooks/use-seo";
import { t } from "i18next";
import { getBrandTranslation } from "../brands/brands";
import { useLocalStorage } from "../../../services/hooks/use-localstorage";
import "./cart.css";
import CartListItem from "./cart-list-item";

export const getCartTranslation = (label = "") => t(`bybest.cart.${label}`);

const ByBestCartProducts = () => {
  const navigate = useNavigate();
  const { pageSEO } = useSeoData();
  const [showMessage, setShowMessage] = useState(false);

  const [cartList, setCartList] = useLocalStorage("shoppingcart", []);

  const onSubmit = () => navigate(`/checkout`);

  const onRemoveProduct = (product) => {
    setCartList(cartList?.filter((item) => item?.id !== product?.id));
    setShowMessage(true);
  };

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        {showMessage && (
          <div className="success-message w-full">
            <span>{getCartTranslation("removeItem")}</span>
          </div>
        )}
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase ">
            {getCartTranslation("breadcrumb")} {getCartTranslation("title")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5 px-4`}>
          <div className="flex justify-start items-center mb-5">
            <FaChevronLeft
              color="#000"
              size={20}
              onClick={() => navigate(-1)}
            />
            <span className=" ml-1 text-[16px] text-[#000] font-semibold">
              {getCartTranslation("continue")}
            </span>
          </div>
          <BybestCartStepper curStep={0} />
          {cartList?.length > 0 ? (
            <div className="mt-5 w-full grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-6">
              <div class="col-span-1 md:col-span-2">
                <CartListItem {...{ onRemoveProduct, cartList }} />

                <div className="mt-5 px-4 py-2 bg-[#D2D2D2] rounded text-[#313131] text-[12px] leading-[18px]">
                  {getCartTranslation("notIncluded")}
                </div>
              </div>

              <div class="col-span-1">
                <div class="w-full rounded bg-[#F2F2F2] p-4 flex flex-col justify-start items-start">
                  <p className="text-[14px] text-[#000]">
                    {getCartTranslation("couponCode")}
                  </p>
                  <div className="mt-3 w-full flex justify-center items-center">
                    <input
                      placeholder={getBrandTranslation("couponCode")}
                      className="h-[45px] flex-1 px-2 rounded border-[2px] border-[#767676]"
                    />
                    <button className=" ml-3 rounded bg-[#000] h-[45px] px-5 font-bold text-[14px] text-white">
                      {getCartTranslation("apply")}
                    </button>
                  </div>
                  <div className="mt-3 w-full flex justify-center items-center">
                    <input
                      placeholder={getBrandTranslation("memberCoupon")}
                      className="h-[45px] flex-1 px-2 rounded border-[2px] border-[#767676]"
                    />
                    <button className=" ml-3 rounded bg-[#000] h-[45px] px-5 font-bold text-[14px] text-white">
                      {getCartTranslation("apply")}
                    </button>
                  </div>
                  <div className="mt-4 w-full flex justify-between items-start">
                    <h2 className="text-[16px] text-[#000] font-bold leading-[24px]">
                      {getCartTranslation("total")}
                    </h2>
                    <div className="flex flex-col justify-end items-end">
                      <h2 className="text-[16px] text-[#000] font-bold leading-[24px]">
                        LEK 250
                      </h2>
                      <p className="mt-2 text-[11px] text-[#000] leading-[14px] uppercase">
                        {getBrandTranslation("reward")} 160{" "}
                        {getBrandTranslation("points")}
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className=" mt-4 rounded bg-[#000] w-full h-[45px]  font-bold text-[14px] text-white"
                  onClick={onSubmit}
                >
                  {getCartTranslation("buy")}
                </button>
              </div>
            </div>
          ) : (
            <div className="mt-5 px-4 py-2 bg-[#D2D2D2] rounded text-[#313131] text-[12px] leading-[18px]">
              {getCartTranslation("noProducts")}
            </div>
          )}
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestCartProducts;
