import React from "react";
import ByBestVerifyEmail from "../../views/register/verify-email/bb-verify-email.jsx";
import ByBestLogin from "../../views/login/by-best-login.jsx";
import ByBestResetPassword from "../../views/resetPassword/by-best-reset-password.jsx";
import ByBestRegister from "../../views/register/by-best-register.jsx";
import ByBestEmailNotVerified from "../../views/register/not_verified/by-best-not-verified.jsx";
import ByBestContact from "../../views/bybest/contact.js";
import ByBestAbout from "../../views/bybest/about.js";
import ByBestStoreAndHours from "../../views/bybest/store_hours.js";
import ByBestLanding from "../../views/bybest/landing/landing.jsx";
import ByBestBlogs from "../../views/bybest/blog.js";
import ByBestBlogDetails from "../../views/bybest/blog-details.js";
import ByBestHome from "../../views/bybest/home/home.js";
import ByBestCategories from "../../views/bybest/category.js";
import ByBestProductDetails from "../../views/bybest/products/product-details.js";
import ByBestCartProducts from "../../views/bybest/cart/cart-products.js";
import ByBestCartUserInfo from "../../views/bybest/cart-userinfo.js";
import ByBestCartPaymethod from "../../views/bybest/cart-paymentmethod.js";
import ByBestCartSuccess from "../../views/bybest/cart-success.js";
import ByBestClub from "../../views/bybest/myclub.js";
import ByBestOrderTracking from "../../views/bybest/order-tracking/index.js";
import ByBestWishList from "../../views/bybest/wishlist/wishlist.js";
import ByBestVertualGiftCard from "../../views/bybest/virtual-giftcard.js";
import ByBestHelp from "../../views/bybest/help.js";
import ByBestFaqs from "../../views/bybest/faqs.js";
import ByBestVacancies from "../../views/bybest/vacancies.js";
import ByBestPrivacyPolicy from "../../views/bybest/privacypolicy.js";
import ByBestCookies from "../../views/bybest/cookies/cookies.js";
import ByBestPriceCurrency from "../../views/bybest/price-currency.js";
import ByBestPaymentMethod from "../../views/bybest/payment-method.js";
import ByBestShippingMethod from "../../views/bybest/shipping-method.js";
import ByBestReturnPolicy from "../../views/bybest/return-policy.js";
import Newsletter from "../../views/bybest/newsletter.jsx";
import DownloadMobileApp from "../../views/bybest/download-mobile-app.jsx";
import ProductSearch from "../../views/bybest/category/product-search.jsx";
import SearchPreview from "../../views/bybest/products/search-preview.jsx";
import TrackingNumber from "../../views/bybest/order-tracking/tracking-number.jsx";
import ByBestLive from "../../views/bybest/live/index.jsx";
import Brands from "../../views/bybest/brands/brands.jsx";
import ByBestRegisterSuccess from "../../views/register/register-successful.jsx";
import ForgotPassword from "../../views/register/forgot-password.jsx";

const bybestGuestRoutes = [
  { path: "/", element: <ByBestHome /> },
  { path: "reset-password", element: <ByBestResetPassword /> },
  { path: "login", element: <ByBestLogin /> },
  { path: "register", element: <ByBestRegister /> },
  { path: "verify-email/:token", element: <ByBestVerifyEmail /> },
  { path: "email-not-verified", element: <ByBestEmailNotVerified /> },
  { path: "registered-sucessfully", element: <ByBestRegisterSuccess /> },
  { path: "page/contact-us", element: <ByBestContact /> },
  { path: "page/about-us", element: <ByBestAbout /> },
  { path: "page/store-and-hours", element: <ByBestStoreAndHours /> },
  { path: "page/landing", element: <ByBestLanding /> },
  { path: "virtual-gift-card", element: <ByBestVertualGiftCard /> },
  { path: "help", element: <ByBestHelp /> },
  { path: "frequently-asked-questions", element: <ByBestFaqs /> },
  { path: "job-listing", element: <ByBestVacancies /> },
  { path: "privacy-policy", element: <ByBestPrivacyPolicy /> },
  { path: "page/cookies", element: <ByBestCookies /> },
  { path: "page/prices-and-currency", element: <ByBestPriceCurrency /> },
  { path: "page/payment-methods", element: <ByBestPaymentMethod /> },
  { path: "page/shipping-methods", element: <ByBestShippingMethod /> },
  { path: "page/return-policy", element: <ByBestReturnPolicy /> },
  { path: "blog/category/:category", element: <ByBestBlogs /> },
  { path: "blog/category/:category/:slug", element: <ByBestBlogDetails /> },
  {
    path: "products/:product_id/:product_url",
    element: <ByBestProductDetails />,
  },
  { path: "cart", element: <ByBestCartProducts /> },
  { path: "checkout", element: <ByBestCartUserInfo /> },
  { path: "payment", element: <ByBestCartPaymethod /> },
  { path: "cart/success", element: <ByBestCartSuccess /> },
  { path: "my-club", element: <ByBestClub /> },
  { path: "tracking", element: <ByBestOrderTracking /> },
  { path: "tracking/:tracking_number", element: <TrackingNumber /> },
  { path: "wishlist", element: <ByBestWishList /> },
  { path: "newsletter", element: <Newsletter /> },
  { path: "download-mobile-app", element: <DownloadMobileApp /> },
  { path: "group/:groupId", element: <ByBestCategories noHeadImg={true} /> },
  { path: "categories/list", element: <ByBestCategories /> },
  { path: "category/:slug", element: <ByBestCategories /> },
  { path: "category/products/search", element: <ProductSearch /> },
  { path: "products/searchPreview", element: <SearchPreview /> },
  { path: "collections/list", element: <ByBestCategories /> },
  { path: "collection/:slug", element: <ByBestCategories /> },
  { path: "collection/products/search", element: <ProductSearch /> },
  { path: "brands/list", element: <Brands /> },
  { path: "brand/:brand_url", element: <ByBestCategories /> },
  { path: "brands/products/search", element: <ProductSearch /> },
  { path: "live", element: <ByBestLive /> },
  { path: "forgot-password", element: <ForgotPassword /> },
];

export default bybestGuestRoutes;
