import React, { useState, useEffect } from "react";
import { FaChevronUp } from "react-icons/fa";

const ScrollToTopButtonByBest = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="bb-scroll">
      <div className="scroll-to-top">
        {isVisible && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-5 left-5 p-3 rounded-full bg-yellow-500 text-white shadow-lg hover:bg-yellow-600 focus:outline-none"
          >
            <FaChevronUp size={20} color="black" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ScrollToTopButtonByBest;
