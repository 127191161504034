import React from "react";
import { customerText } from "../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const CustomerFooterButtons = ({
  onCancel,
  onSave,
  saveText = customerText("save"),
  cancelText = customerText("cancel"),
}) => {
  return (
    <div className="flex justify-end gap-3 mt-6 md:mt-0">
      <button
        onClick={onCancel}
        className="h-[38px] border-[1px] border-[#D1D5DB] rounded-[6px] bg-white px-[17px] py-2 text-14 text-[#374151] font-semibold"
      >
        {cancelText}
      </button>
      <button
        onClick={onSave}
        className="h-[38px] rounded-[6px] bg-[#cb0000] px-[17px] py-2 text-14 text-white font-semibold"
      >
        {saveText}
      </button>
    </div>
  );
};

export default CustomerFooterButtons;
