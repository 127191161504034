import { Box, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { MSG_TYPE_TEXT } from "../../../components/Messages/react-web-gifted-chat/Constant";
import FooterChatSection from "./components/footer-chat-section";
import HeaderChatSection from "./components/header-chat-section";
import "./index.css";
import {
  createAtStyles,
  imageStyles,
  isTextStyles,
  noTextStyles,
  receievedMessageStyles,
  style,
} from "./styles";
import { useMatchMedia } from "../../../services/hooks/use-match-media";

const ChatModal = ({ open, handleClose }) => {
  const [text, setText] = useState("");
  const endOfMessagesRef = useRef(null);

  const isMobile = useMatchMedia();

  const [conversation, setConversation] = useState([
    {
      id: 1,
      content: "Hello! How can I help you today?",
      type: MSG_TYPE_TEXT,
      sentByMe: false,
      created_at: new Date(),
    },
    {
      id: 2,
      content: "I need help with my account.",
      type: MSG_TYPE_TEXT,
      sentByMe: true,
      created_at: new Date(),
    },
  ]);

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);

  const handleSendMessage = () => {
    if (text.length > 0) {
      const newMessage = {
        id: conversation.length + 1,
        content: text,
        type: MSG_TYPE_TEXT,
        sentByMe: true,
        created_at: new Date(),
      };
      setConversation([...conversation, newMessage]);
      setText("");
    }

    setTimeout(() => {
      const replyMessage = {
        id: conversation.length + 2,
        content: "Thanks for reaching out! Let me check that for you.",
        type: MSG_TYPE_TEXT,
        sentByMe: false,
        created_at: new Date(),
      };
      setConversation((prev) => [...prev, replyMessage]);
    }, 1000);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-chat-title"
      aria-describedby="modal-chat-description"
      disableEnforceFocus
      keepMounted
    >
      <Box sx={style(isMobile)}>
        <HeaderChatSection />

        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          sx={{
            marginTop: 2,
            marginBottom: 4,
            maxHeight: isMobile ? "50vh" : "80vh",
            overflowY: "auto",
          }}
          className="chat-container"
        >
          {conversation.map((msg) => (
            <div key={msg.id} className="flex flex-col">
              <Box
                display="flex"
                flexDirection={msg.sentByMe ? "row-reverse" : "row"}
                alignItems="flex-start"
              >
                {!msg.sentByMe && (
                  <Box sx={receievedMessageStyles(msg)}>GG</Box>
                )}
                {msg.type === MSG_TYPE_TEXT ? (
                  <Box sx={isTextStyles(msg)}>
                    <span>{msg.content ?? msg?.text}</span>
                  </Box>
                ) : (
                  <Box sx={noTextStyles(msg)}>
                    <img alt="" src={msg.content} style={imageStyles} />
                  </Box>
                )}
              </Box>
              {msg.created_at && (
                <Box component="span" sx={createAtStyles(msg)}>
                  {new Date(msg.created_at).toLocaleTimeString()}
                </Box>
              )}
            </div>
          ))}
          <div ref={endOfMessagesRef} />
        </Box>

        <FooterChatSection {...{ handleSendMessage, setText, text }} />
      </Box>
    </Modal>
  );
};

export default ChatModal;
