import React from "react";

const BBMainLayout = ({ children, clientKey = "bybest", seo = {} }) => {
  return (
    <>
      <body>
        {children}
      </body>
    </>
  );
};

export default BBMainLayout;
