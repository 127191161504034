import React, { useState } from "react";
import { FiHeart } from "react-icons/fi";
import BybestProductItem from "../../../components/BybestProductItem";
import { products } from "../data";
import BBMainLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../../services/hooks/use-seo";
import { data } from "../components/data";
import { CollapseMenu } from "../components/collapse-menu";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import BybestCheckoutModal from "../../../components/BybestCheckoutModal";
import { getBrandTranslation } from "../brands/brands";
import { t } from "i18next";
import ProductChildrenOptions from "./product-children-options";
import { useLocalStorage } from "../../../services/hooks/use-localstorage";
import { getCartTranslation } from "../cart/cart-products";

export const getProductsText = (label = "") => t(`bybest.products.${label}`);

const ByBestProductDetails = () => {
  const { app_key = "BYB2802APPY", product_id } = useParams();
  const { pageSEO } = useSeoData();
  const [searchParams] = useSearchParams();
  const errorParam = searchParams.get("error");
  const navigate = useNavigate();
  const location = useLocation();

  const [showCheckoutModal, setShowCheckoutModal] = useState(errorParam ?? false);
  const [activeMenu, setActiveMenu] = useState(data(getProductsText)[0]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [product, setProduct] = useState({
    id: 10,
    title: "LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B",
    category: "LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B",
    img1: "https://admin.bybest.shop/storage/products/62447fc3e1f44_KLE20QB101-01 frusta.jpg",
    img2: "https://admin.bybest.shop/storage/products/62447fc3ecd4b_KLE20QB101-03.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  });
  const [cartList, setCartList] = useLocalStorage("shoppingcart", []);
  const [showMessage, setShowMessage] = useState(false);

  const imageArray = [
    product.img1,
    product.img2,
    product.img1,
    product.img2,
    product.img1,
    product.img2,
  ];

  const onGoProductDetails = (p) => {
    setProduct(p);
    setSelectedImage(p.img1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onShoppingcartChange = (product) => {
    if (
      cartList?.some((item) => item.id !== product.id) ||
      cartList?.length === 0
    ) {
      setCartList([...cartList, { ...product, sale_price: product.price }]);
      setShowMessage(true);
    }
  };

  
  const handleCloseModal = ()=>{
    errorParam && navigate(location.pathname);
    setShowCheckoutModal(false);
  }

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white product-details">
        {showMessage && (
          <div className="success-message w-full">
            <span>{getCartTranslation("addItem")}</span>
          </div>
        )}
        {/* Breadcrumb */}
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <span className="leading-[36px] md:text-16 text-[13px] md:leading-[24px] text-center text-[#000] uppercase">
            {getBrandTranslation("breadcrumb")}{" "}
            {getBrandTranslation("product")} {`>`} {product?.title}
          </span>
        </div>

        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-12 gap-0 md:gap-8">
            {/* Sidebar Images */}
            <div className="col-span-1 md:col-span-7 flex flex-col md:flex-row-reverse">
              <div className="flex-1 mt-3 md:mt-0 md:ml-3" id="full_data">
                <img
                  id="expandedImg"
                  alt={product?.title}
                  src={selectedImage || product?.img1}
                  className="w-full"
                />
              </div>
              <div className="w-full max-w-[90vw] overflow-x-auto md:w-[80px] flex gap-[1px] md:flex-col justify-start items-center md:items-start">
                {imageArray.map((imgSrc, index) => (
                  <img
                    key={index}
                    src={imgSrc}
                    alt={product?.title}
                    className="w-[130px] h-[130px] md:w-full md:h-auto mt-3 cursor-pointer"
                    onClick={() => setSelectedImage(imgSrc)}
                  />
                ))}
              </div>
            </div>

            {/* Product Details Section */}
            <div className="col-span-1 md:col-span-5 mt-8 md:mt-0">
              <div className="w-full flex flex-col justify-start items-start">
                <h1 className="text-[#000] text-[18px] font-bold uppercase">
                  {product?.title}
                </h1>

                <div className="description">{product?.category}</div>
                <p className="text-[#000] text-[13px] uppercase">
                  {getBrandTranslation("brandName")}: Mopita
                </p>
                <p className="text-[#000] text-[13px] uppercase">
                  {getBrandTranslation("articleNumber")} : {product?.no}
                </p>

                <div className="price">
                  <span className="discounted-price">
                    {getBrandTranslation("currency")} {product?.sale_price}
                  </span>
                  <span className="old-price">
                    {getBrandTranslation("currency")} {product?.price}
                  </span>
                  <span className="percentage">-{product?.discount}</span>
                </div>

                <div className="points-info">
                  {getBrandTranslation("rewardPoints")}
                </div>

                {Number(product_id) % 2 === 0 && <ProductChildrenOptions />}

                <div className="my-4 w-full flex justify-center items-center">
                  <div className="w-[47px] h-[47px] rounded-[4px] flex justify-center items-center border border-[#000] mr-4">
                    <FiHeart size={24} color={"#000"} />
                  </div>
                  <button
                    onClick={() => onShoppingcartChange(product)}
                    type="button"
                    className="flex-1 h-[47px] rounded-[4px] border border-[#000] text-[#000] text-[14px] font-medium uppercase"
                  >
                    {getBrandTranslation("addToCart")}
                  </button>
                </div>
                <button
                  type="button"
                  className="w-full h-[47px] rounded-[4px] bg-[#000] text-[white] text-[14px] font-medium uppercase"
                  onClick={() => setShowCheckoutModal(true)}
                >
                  {getBrandTranslation("buyWithOneClick")}
                </button>
                <div className="w-full mt-5">
                  {data(getProductsText).map((info) => (
                    <CollapseMenu
                      key={info.label}
                      data={info}
                      isOpened={info.label === activeMenu?.label}
                      onSelect={(item) => {
                        if (item.label === activeMenu?.label) {
                          setActiveMenu(null);
                        } else {
                          setActiveMenu(item);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Similar Products */}
          <div className="w-full mt-5 px-4">
            <div className="section-title">
              {getBrandTranslation("similarProducts")}
            </div>
            <div className="hidden mt-5 md:grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-8">
              {products.slice(0, 4).map((item, index) => (
                <BybestProductItem
                  key={index}
                  type="similar"
                  data={item}
                  onClick={() => onGoProductDetails(item)}
                />
              ))}
            </div>

            <div className="mt-5 flex max-w-[100vw] overflow-x-auto md:hidden">
              {products.slice(0, 4).map((pp, index) => (
                <div key={index} className="pr-3 min-w-[220px]">
                  <BybestProductItem
                    data={pp}
                    type="home"
                    onClick={() => onGoProductDetails(pp)}
                    className="w-[60vw]"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Checkout Modal */}
        {showCheckoutModal && (
          <BybestCheckoutModal
          errorParam={errorParam}
            product={product}
            app_key={app_key}
            showModal={showCheckoutModal}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </BBMainLayout>
  );
};

export default ByBestProductDetails;
