import React from "react";
import DropdownFilter from "../../../components/Dropdown/dropdown-filter";
import BybestCustomerLayout, {
  customerText,
} from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerHeader from "../customer-header";
import { wishlistItems } from "./data";
import "./index.css";
import WishlistItems from "./wishlist-items";

const CustomerWishlist = () => {
  return (
    <BybestCustomerLayout selected="wishlist" key="wishlist">
      <div className="flex flex-1 flex-col gap-5">
        <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg mt-6">
          <div className="w-full p-2 md:p-6">
            <CustomerHeader
              description={customerText("wishlistDescription")}
              numberOfItems={wishlistItems.length}
              items={customerText("products")}
              title={customerText("wishlist")}
            />
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 my-5">
              <WishlistItems items={wishlistItems} />
            </div>
            <div className="flex justify-center">
              <DropdownFilter showIcon={false} title="Load More" />
            </div>
          </div>
        </div>
      </div>
    </BybestCustomerLayout>
  );
};

export default CustomerWishlist;
