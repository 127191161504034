import React from "react";
import { Switch } from "antd";
import PhoneIcon from "../../../assets/svgs/phone.svg";
import InboxIcon from "../../../assets/svgs/inbox.svg";
import CustomerHeader from "../customer-header";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const CommunicationPreferences = ({
  switchStatus,
  onSwitchChange,
  title = "",
  description = "",
}) => {
  return (
    <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg flex flex-col pr-4">
      <div className="p-6 ">
        <CustomerHeader
          {...{ title, description }}
          showItems={false}
          showHome={false}
        />
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] " />
      <div className="flex flex-col md:flex-row gap-4 p-6">
        <div className="w-[48px] h-[48px] p-[10px] align-middle rounded-[4px] bg-[#cb0000]">
          <img alt="" src={PhoneIcon} />
        </div>
        <div className="flex justify-between flex-col flex-1">
          <p className="text-[#101828] text-14  md:text-20 font-semibold leading-5 ">
            {customerText("smsNotifications")}
            <span className="ml-2 text-[#240b3b] text-14 md:text-[16px] font-medium bg-[#F9F5FF] rounded-[16px] px-2 py-[2px]">
              0 {customerText("smsReceived")}
            </span>
          </p>
          <p className="text-[#667085] text-14 font-medium leading-5">
            {customerText("smsDescription")}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-[#240b3b] text-14 font-medium leading-5">
            {switchStatus ? customerText("on") : customerText("off")}
          </p>
          <Switch
            defaultChecked
            onChange={onSwitchChange}
            className="PI-switch"
          />
        </div>
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] mx-6" />
      <div className="flex flex-col md:flex-row gap-4 p-6">
        <div className="w-[48px] h-[48px] p-[10px] align-middle rounded-[4px] bg-[#cb0000]">
          <img alt="" src={InboxIcon} />
        </div>
        <div className="flex justify-between flex-col flex-1">
          <p className="text-[#101828] text-14 md:text-20 font-semibold leading-5 ">
            {customerText("emailNotifications")}
            <span className="ml-2 text-[#240b3b] text-14 md:text-[16px] font-medium bg-[#F9F5FF] rounded-[16px] px-2 py-[2px]">
              0 {customerText("emailReceived")}
            </span>
          </p>
          <p className="text-[#667085] text-14 font-medium leading-5">
            {customerText("emailDescription")}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-[#240b3b] text-14 font-medium leading-5">
            {switchStatus ? customerText("on") : customerText("off")}
          </p>
          <Switch
            defaultChecked
            onChange={onSwitchChange}
            className="PI-switch"
          />
        </div>
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] " />
    </div>
  );
};

export default CommunicationPreferences;
