import React from "react";
import BybestCustomerLayout, {
  customerText,
} from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerHeader from "../customer-header";
import ChangePassword from "./change-password";
import "./index.css";
import LoginSecurityActivities from "./login-security-activities";

const LoginSecurity = () => {
  return (
    <BybestCustomerLayout selected="login-security" key="login-security">
      <div className="flex flex-1 flex-col gap-5 ">
        <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg mt-6">
          <div className="p-6 gap-2 flex flex-col">
            <CustomerHeader
              title={customerText("loginSecurityTitle")}
              description={customerText("loginSecurityDescription")}
              showItems={false}
            />
            <hr className="border-[1px]  border-[#E5E7EB] my-5" />

            <div className="grid lg:grid-cols-2 gap-5">
              <ChangePassword />
              <LoginSecurityActivities />
            </div>
          </div>
        </div>
      </div>
    </BybestCustomerLayout>
  );
};

export default LoginSecurity;
