import React from "react";
import Carousel from "react-bootstrap/Carousel";

const BybestHomeCarousel = ({ sliders }) => {
  return (
    <div className="w-full relative homeSlider">
      <Carousel>
        {sliders?.map((item, index) => (
          <Carousel.Item key={index}>
            <img src={item.photo} className="w-[100%] object-cover" alt="" />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default BybestHomeCarousel;
