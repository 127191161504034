import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BybestCategoryFilter from "../../components/BybestCategoryFilter";
import BybestProductItem from "../../components/BybestProductItem";
import { products as categoryProducts } from "./data";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { getBrandTranslation } from "./brands/brands";
import { useLocalStorage } from "../../services/hooks/use-localstorage";
import { getCartTranslation } from "./cart/cart-products";

const ByBestCategories = ({noHeadImg = false}) => {
  const { slug, app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [products, setProducts] = useState(categoryProducts);
  const [showMessage, setShowMessage] = useState();

  const onGoProductDetails = (p) => {
    navigate(`/products/${p.id}/${slug}`);
  };

  const { pageSEO } = useSeoData();

  const [productList, setProductList] = useLocalStorage("wishlist", []);

  useEffect(() => {
    const updatedProducts = categoryProducts?.map((item) => ({...item,favorite: productList.some((favItem) => favItem.id === item.id),}));
     
    setProducts(updatedProducts);
  }, [productList]);

  const onFavoriteChange = (product) => {
    if (productList?.some((item) => item.id === product.id)) {
      setProductList(productList?.filter((item) => item.id !== product.id));
      setProducts(
        categoryProducts.map((p) => p.id === product.id ? { ...p, favorite: false } : p)
      );
      setShowMessage(getCartTranslation("removeItem"))
    } else {
      setProductList([...productList, product]);
      setProducts(
        categoryProducts.map((p) => p.id === product.id ? { ...p, favorite: true } : p)
      );
      setShowMessage(getCartTranslation("addItem"))
    }
  };

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
      { showMessage && (
          <div className="success-message w-full">
            <span>{showMessage}</span>
          </div>
        )}
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcrumbs">
          <p className="leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase ">
            {getBrandTranslation("breadcrumb")} {`${state?.category} ${state?.item ? `> ${state?.item}`: ""}`}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          {!noHeadImg && <div className="w-full flex justify-center items-center pt-[20px] pb-[60px]">
            <hr className="flex-1" />
            <img
              alt=""
              src="https://admin.bybest.shop/storage/brands/brands_colored_1654870085.png"
              className="max-w-[250px] max-h-[80px] min-h-[30px] px-[20px]"
            />
            <hr className="flex-1" />
          </div>}
          <div className="w-full px-4 md:px-0 grid grid-cols-1 md:grid-cols-4 gap-0 md:gap-8">
            <BybestCategoryFilter />
            <div className=" col-span-3">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-8">
                {products.map((p, index) => (
                  <BybestProductItem
                    key={index}
                    data={p}
                    type="home"
                    onClick={() => onGoProductDetails(p)}
                    onFavoriteChange={onFavoriteChange}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestCategories;
