import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getDuration } from "../../utils/common";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const CouponTimer = ({ coupon_end_data }) => {
  const [time, setTime] = useState(getDuration(new Date(), coupon_end_data));
  useEffect(() => {
    setInterval(() => {
      setTime(getDuration(new Date(), coupon_end_data));
    }, 1000);
  }, []);
  return (
    <div className="text-white text-[13px] leading-[14px]  font-bold">
      {time}
    </div>
  );
};

const BybestTopCarousel = ({ coupon_end_data }) => {
  const data = [
    { title: "SHOPPING WITH STYLE AND QUALITY", subTitle: "" },
    {
      title: 'FINAL SALE | "BLUKIDS & IANA" 60% DISCOUNT | 05-12 AUGUST  ',
      subTitle: <CouponTimer coupon_end_data={coupon_end_data} />,
    },
  ];

  return (
    <div className="w-full flex justify-center items-center fixed z-20 bg-[#3b3b3a] px-4">
      <div className="w-full max-w-screen-main relative">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          showDots={false}
          partialVisible={false}
          arrows={false}
        >
          {data.map((item, index) => (
            <div
              className="w-full h-[32px] bg-[#3b3b3a] flex flex-col justify-center items-center"
              key={index}
            >
              <div className="text-[#eab600] text-[13px] leading-[14px] font-bold">
                {item.title}
              </div>
              {item.subTitle}
            </div>
          ))}
        </Carousel>
        <div className=" absolute left-[10px] md:left-[-10px] top-[3px]">
          <ChevronLeft style={{ color: "#f2f2f2" }} />
        </div>
        <div className=" absolute right-[10px] md:right-[-10px] top-[3px]">
          <ChevronRight style={{ color: "#f2f2f2" }} />
        </div>
      </div>
    </div>
  );
};

export default BybestTopCarousel;
