import React, { useMemo, useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { formatNumber } from "../../utils/common";

const ProductPrice = ({
    product,
}) => {
    const { t } = useTranslation();
    const currency = 'LEK'; // TO DO : get from redux
    const exchange = 1;

    const sale_valid = useMemo(() => {
        let temp_date_start = product.product_type === 'single' ? moment(product.date_sale_start) : moment(product.var_date_sale_start);
        let temp_date_end = product.product_type === 'single' ? moment(product.date_sale_end) : moment(product.var_date_sale_end);

        return moment().isBetween(temp_date_start, temp_date_end);
    }, [product])

    if (product.currency_alpha != currency) {
        if (product.product_type == 'single') {
            if (product.price && product.currency_alpha) {
                if (sale_valid) {
                    return (
                        <div class="price-details">
                            <span class="old-price">
                                {currency} {formatNumber(product.price * exchange)}
                            </span>
                            <span class="discount">-{product.sale_price}%</span>
                            <span class="new-price">
                                {currency} {formatNumber((product.price - (product.price * (product.sale_price / 100))) * exchange)}</span>
                        </div>
                    )
                }
                else {
                    return (
                        <div class="price-details">
                            <span>{currency} {formatNumber(product.price * exchange)}</span>
                            <span class="old-price"></span>
                            <span class="discount"></span>
                            <span class="new-price"></span>
                        </div>
                    )
                }
            }
            else {
                return (
                    <div class="price-details">
                        <span>No Price</span>
                    </div>
                )
            }
        }
        else if (product.max_regular_price && (product.count_currency_alpha > 0)) {
            if (sale_valid) {
                return (
                    <div class="price-details">
                        {
                            ((product.min_regular_price === product.max_regular_price) && product.max_regular_price > 0) ?
                                <>
                                    <span class="old-price">
                                        {currency} {formatNumber(product.max_regular_price * exchange)}
                                    </span>
                                    <span class="discount">-{product.var_sale_price}%</span>
                                    <span class="new-price">
                                        {currency} {formatNumber((product.max_regular_price - (product.max_regular_price * (product.var_sale_price / 100))) * exchange)}
                                    </span>
                                </>
                                :
                                <>
                                    <span class="old-price">
                                        {currency} {formatNumber(product.min_regular_price * exchange)} -
                                        {currency}  {formatNumber(product.max_regular_price * exchange)}
                                    </span>
                                    <span class="discount">-{product.var_sale_price}%</span>
                                    <span class="new-price">
                                        {currency} {formatNumber((product.min_regular_price - (product.min_regular_price * (product.var_sale_price / 100))) * exchange)} -
                                        {currency} {formatNumber((product.max_regular_price - (product.max_regular_price * (product.var_sale_price / 100))) * exchange)}
                                    </span>
                                </>
                        }
                    </div>
                )
            }
            else {
                return (
                    <div class="price-details">
                        {
                            ((product.min_regular_price === product.max_regular_price) && product.max_regular_price > 0) ?
                                <span>{currency} {formatNumber(product.max_regular_price * exchange)}</span>
                                :
                                <span>
                                    {currency} {formatNumber(product.min_regular_price * exchange)} -
                                    {currency}  {formatNumber(product.max_regular_price * exchange)}
                                </span>
                        }
                        <span class="old-price"></span>
                        <span class="discount"></span>
                        <span class="new-price"></span>
                    </div>
                );
            }
        }
        return (
            <div class="price-details">
                <span>No Price</span>
            </div>
        )
    }
    else {
        if (product.product_type == 'single') {
            if (product.price && product.currency_alpha) {
                if (sale_valid) {
                    return (
                        <div class="price-details">
                            <span class="old-price">{currency} {formatNumber(product.price)}</span>
                            <span class="discount">-{product.sale_price}%</span>
                            <span class="new-price">{currency} {formatNumber(product.price - (product.price * (product.sale_price / 100)))}
                            </span>
                        </div>
                    )
                }
                else {
                    return (
                        <div class="price-details">
                            <span>{currency} {formatNumber(product.price)}</span>
                            <span class="old-price"></span>
                            <span class="discount"></span>
                            <span class="new-price"></span>
                        </div>
                    )
                }
            }
            else {
                return (
                    <div class="price-details">
                        <span>No Price</span>
                    </div>
                )
            }
        }
        else if (product.max_regular_price && (product.count_currency_alpha > 0))
            if (sale_valid) {
                return (
                    <div class="price-details">
                        {
                            ((product.min_regular_price === product.max_regular_price) && product.max_regular_price > 0) ?
                                <>
                                    <span class="old-price">
                                        {currency} {formatNumber(product.max_regular_price)}
                                    </span>
                                    <span class="discount">-{product.var_sale_price}%</span>
                                    <span class="new-price">
                                        {currency} {formatNumber(product.max_regular_price - (product.max_regular_price * (product.var_sale_price / 100)))}
                                    </span>
                                </>
                                :
                                <>
                                    <span class="old-price">
                                        {currency} {formatNumber(product.min_regular_price)} -
                                        {currency}  {formatNumber(product.max_regular_price)}
                                    </span>
                                    <span class="discount">-{product.var_sale_price}%</span>
                                    <span class="new-price">
                                        {currency} {formatNumber(product.min_regular_price - (product.min_regular_price * (product.var_sale_price / 100)))} -
                                        {currency} {formatNumber(product.max_regular_price - (product.max_regular_price * (product.var_sale_price / 100)))}
                                    </span>
                                </>
                        }
                    </div>
                );
            }
            else {
                return (
                    <div class="price-details">
                        {((product.min_regular_price === product.max_regular_price) && product.max_regular_price > 0) ?
                            <span>{currency} {formatNumber(product.max_regular_price)}</span>
                            :
                            <span>
                                {currency} {formatNumber(product.min_regular_price)} -
                                {currency}  {formatNumber(product.max_regular_price)}
                            </span>
                        }
                        <span class="old-price"></span>
                        <span class="discount"></span>
                        <span class="new-price"></span>
                    </div>
                );
            }
    }
    return (
        <div class="price-details">
            <span>No Price</span>
        </div>
    )
}

export default ProductPrice;